export default [
  {
    name_en: "Afghanistán",
    name_ca: "Afganistan",
    name_es: "Afganistan",
    dial_code: "+93",
    code: "AF",
    code_alpha_2: "AF",
    code_alpha_3: "AFG",
  },
  {
    name_en: "Albania",
    name_ca: "Albània",
    name_es: "Albania",
    dial_code: "+355",
    code: "AL",
    code_alpha_2: "AL",
    code_alpha_3: "ALB",
  },
  {
    name_en: "Algeria",
    name_ca: "Algèria",
    name_es: "Argelia",
    dial_code: "+213",
    code: "DZ",
    code_alpha_2: "DZ",
    code_alpha_3: "DZA",
  },
  {
    name_en: "AmericanSamoa",
    name_ca: "Samoa Americana",
    name_es: "Samoa Americana",
    dial_code: "+1 684",
    code: "AS",
    code_alpha_2: "AS",
    code_alpha_3: "ASM",
  },
  {
    name_en: "Andorra",
    name_ca: "Andorra",
    name_es: "Andorra",
    dial_code: "+376",
    code: "AD",
    code_alpha_2: "AD",
    code_alpha_3: "AND",
  },
  {
    name_en: "Angola",
    name_ca: "Angola",
    name_es: "Angola",
    dial_code: "+244",
    code: "AO",
    code_alpha_2: "AO",
    code_alpha_3: "AGO",
  },
  {
    name_en: "Anguilla",
    name_ca: "Anguilla",
    name_es: "Anguilla",
    dial_code: "+1 264",
    code: "AI",
    code_alpha_2: "AI",
    code_alpha_3: "AIA",
  },
  {
    name_en: "Antarctica",
    name_ca: "Antartida",
    name_es: "Antartida",
    dial_code: "+672",
    code: "AQ",
    code_alpha_2: "AQ",
    code_alpha_3: "ATA",
  },
  {
    name_en: "Antigua and Barbuda",
    name_ca: "Antigua i Barbuda",
    name_es: "Antigua y Barbuda",
    dial_code: "+1268",
    code: "AG",
    code_alpha_2: "AG",
    code_alpha_3: "ATG",
  },
  {
    name_en: "Argentina",
    name_ca: "Argentina",
    name_es: "Argentina",
    dial_code: "+54",
    code: "AR",
    code_alpha_2: "AR",
    code_alpha_3: "ARG",
  },
  {
    name_en: "Armenia",
    name_ca: "Armènia",
    name_es: "Armenia",
    dial_code: "+374",
    code: "AM",
    code_alpha_2: "AM",
    code_alpha_3: "ARM",
  },
  {
    name_en: "Aruba",
    name_ca: "Aruba",
    name_es: "Aruba",
    dial_code: "+297",
    code: "AW",
    code_alpha_2: "AW",
    code_alpha_3: "ABW",
  },
  {
    name_en: "Australia",
    name_ca: "Austràlia",
    name_es: "Australia",
    dial_code: "+61",
    code: "AU",
    code_alpha_2: "AU",
    code_alpha_3: "AUS",
  },
  {
    name_en: "Austria",
    name_ca: "Àustria",
    name_es: "Austria",
    dial_code: "+43",
    code: "AT",
    code_alpha_2: "AT",
    code_alpha_3: "AUT",
  },
  {
    name_en: "Azerbaijan",
    name_ca: "Azerbaidjan",
    name_es: "Azerbaiyan",
    dial_code: "+994",
    code: "AZ",
    code_alpha_2: "AZ",
    code_alpha_3: "AZE",
  },
  {
    name_en: "Bahamas",
    name_ca: "Bahames",
    name_es: "Bahamas",
    dial_code: "+1 242",
    code: "BS",
    code_alpha_2: "BS",
    code_alpha_3: "BHS",
  },
  {
    name_en: "Bahrain",
    name_ca: "Bahrain",
    name_es: "Barein",
    dial_code: "+973",
    code: "BH",
    code_alpha_2: "BH",
    code_alpha_3: "BHR",
  },
  {
    name_en: "Bangladesh",
    name_ca: "Bangla Desh",
    name_es: "Banglades",
    dial_code: "+880",
    code: "BD",
    code_alpha_2: "BD",
    code_alpha_3: "BGD",
  },
  {
    name_en: "Barbados",
    name_ca: "Barbados",
    name_es: "Barbados",
    dial_code: "+1 246",
    code: "BB",
    code_alpha_2: "BB",
    code_alpha_3: "BRB",
  },
  {
    name_en: "Belarus",
    name_ca: "Bielorússia",
    name_es: "Bielorrusia",
    dial_code: "+375",
    code: "BY",
    code_alpha_2: "BY",
    code_alpha_3: "BLR",
  },
  {
    name_en: "Belgium",
    name_ca: "Bèlgica",
    name_es: "Bélgica",
    dial_code: "+32",
    code: "BE",
    code_alpha_2: "BE",
    code_alpha_3: "BEL",
  },
  {
    name_en: "Belize",
    name_ca: "Belize",
    name_es: "Belice",
    dial_code: "+501",
    code: "BZ",
    code_alpha_2: "BZ",
    code_alpha_3: "BLZ",
  },
  {
    name_en: "Benin",
    name_ca: "Benín",
    name_es: "Benin",
    dial_code: "+229",
    code: "BJ",
    code_alpha_2: "BJ",
    code_alpha_3: "BEN",
  },
  {
    name_en: "Bermuda",
    name_ca: "Bermudes",
    name_es: "Bermudas",
    dial_code: "+1 441",
    code: "BM",
    code_alpha_2: "BM",
    code_alpha_3: "BMU",
  },
  {
    name_en: "Bhutan",
    name_ca: "Butan",
    name_es: "Butan",
    dial_code: "+975",
    code: "BT",
    code_alpha_2: "BT",
    code_alpha_3: "BTN",
  },
  {
    name_en: "Bolivia",
    name_ca: "Bolívia",
    name_es: "Bolivia",
    dial_code: "+591",
    code: "BO",
    code_alpha_2: "BO",
    code_alpha_3: "BOL",
  },
  {
    name_en: "Bosnia and Herzegovina",
    name_ca: "Bòsnia i Hercegovina",
    name_es: "Bosnia-Herzegovina",
    dial_code: "+387",
    code: "BA",
    code_alpha_2: "BA",
    code_alpha_3: "BIH",
  },
  {
    name_en: "Botswana",
    name_ca: "Botswana",
    name_es: "Botsuana",
    dial_code: "+267",
    code: "BW",
    code_alpha_2: "BW",
    code_alpha_3: "BWA",
  },
  {
    name_en: "Brazil",
    name_ca: "Brasil",
    name_es: "Brasil",
    dial_code: "+55",
    code: "BR",
    code_alpha_2: "BR",
    code_alpha_3: "BRA",
  },
  {
    name_en: "British Indian Ocean Territory",
    name_ca: "Territori Britànic de l'Oceà Índic",
    name_es: "Territorio Británico del Océano Índico",
    dial_code: "+246",
    code: "IO",
    code_alpha_2: "IO",
    code_alpha_3: "IOT",
  },
  {
    name_en: "Brunei Darussalam",
    name_ca: "Brunei",
    name_es: "Brunei",
    dial_code: "+673",
    code: "BN",
    code_alpha_2: "BN",
    code_alpha_3: "BRN",
  },
  {
    name_en: "Bulgaria",
    name_ca: "Bulgària",
    name_es: "Bulgaria",
    dial_code: "+359",
    code: "BG",
    code_alpha_2: "BG",
    code_alpha_3: "BGR",
  },
  {
    name_en: "Burkina Faso",
    name_ca: "Burkina Faso",
    name_es: "Burkina Faso",
    dial_code: "+226",
    code: "BF",
    code_alpha_2: "BF",
    code_alpha_3: "BFA",
  },
  {
    name_en: "Burundi",
    name_ca: "Burundi",
    name_es: "Burundi",
    dial_code: "+257",
    code: "BI",
    code_alpha_2: "BI",
    code_alpha_3: "BDI",
  },
  {
    name_en: "Cambodia",
    name_ca: "Cambodja",
    name_es: "Camboya",
    dial_code: "+855",
    code: "KH",
    code_alpha_2: "KH",
    code_alpha_3: "KHM",
  },
  {
    name_en: "Cameroon",
    name_ca: "Camerun",
    name_es: "Camerun",
    dial_code: "+237",
    code: "CM",
    code_alpha_2: "CM",
    code_alpha_3: "CMR",
  },
  {
    name_en: "Canada",
    name_ca: "Canada",
    name_es: "Canada",
    dial_code: "+1",
    code: "CA",
    code_alpha_2: "CA",
    code_alpha_3: "CAN",
  },
  {
    name_en: "Cape Verde",
    name_ca: "Cap Verd",
    name_es: "Cabo Verde",
    dial_code: "+238",
    code: "CV",
    code_alpha_2: "CV",
    code_alpha_3: "CPV",
  },
  {
    name_en: "Cayman Islands",
    name_ca: "Illes Caiman",
    name_es: "Islas Caiman",
    dial_code: "+ 345",
    code: "KY",
    code_alpha_2: "KY",
    code_alpha_3: "CYM",
  },
  {
    name_en: "Central African Republic",
    name_ca: "República Centreafricana",
    name_es: "Republica Centroafricana",
    dial_code: "+236",
    code: "CF",
    code_alpha_2: "CF",
    code_alpha_3: "CAF",
  },
  {
    name_en: "Chad",
    name_ca: "Txad",
    name_es: "Chad",
    dial_code: "+235",
    code: "TD",
    code_alpha_2: "TD",
    code_alpha_3: "TCD",
  },
  {
    name_en: "Chile",
    name_ca: "Xile",
    name_es: "Chile",
    dial_code: "+56",
    code: "CL",
    code_alpha_2: "CL",
    code_alpha_3: "CHL",
  },
  {
    name_en: "China",
    name_ca: "Xina",
    name_es: "China",
    dial_code: "+86",
    code: "CN",
    code_alpha_2: "CN",
    code_alpha_3: "CHN",
  },
  {
    name_en: "Christmas Island",
    name_ca: "Illa de Nadal",
    name_es: "Isla de Navidad",
    dial_code: "+61",
    code: "CX",
    code_alpha_2: "CX",
    code_alpha_3: "CXR",
  },
  {
    name_en: "Cocos (Keeling) Islands",
    name_ca: "Illes Cocos",
    name_es: "Islas Cocos",
    dial_code: "+61",
    code: "CC",
    code_alpha_2: "CC",
    code_alpha_3: "CCK",
  },
  {
    name_en: "Colombia",
    name_ca: "Colòmbia",
    name_es: "Colombia",
    dial_code: "+57",
    code: "CO",
    code_alpha_2: "CO",
    code_alpha_3: "COL",
  },
  {
    name_en: "Comoros",
    name_ca: "Comores",
    name_es: "Comoras",
    dial_code: "+269",
    code: "KM",
    code_alpha_2: "KM",
    code_alpha_3: "COM",
  },
  {
    name_en: "Congo",
    name_ca: "Congo",
    name_es: "Congo",
    dial_code: "+242",
    code: "CG",
    code_alpha_2: "CG",
    code_alpha_3: "COG",
  },
  {
    name_en: "Congo, The Democratic Republic of the",
    name_ca: "República Democràtica de Congo",
    name_es: "República Democrática del Congo",
    dial_code: "+243",
    code: "CD",
    code_alpha_2: "CD",
    code_alpha_3: "COD",
  },
  {
    name_en: "Cook Islands",
    name_ca: "Illes Cook",
    name_es: "Islas Cook",
    dial_code: "+682",
    code: "CK",
    code_alpha_2: "CK",
    code_alpha_3: "COK",
  },
  {
    name_en: "Costa Rica",
    name_ca: "Costa Rica",
    name_es: "Costa Rica",
    dial_code: "+506",
    code: "CR",
    code_alpha_2: "CR",
    code_alpha_3: "CRI",
  },
  {
    name_en: "Cote d'Ivoire",
    name_ca: "Costa d'Ivori",
    name_es: " Costa de Marfil",
    dial_code: "+225",
    code: "CI",
    code_alpha_2: "CI",
    code_alpha_3: "CIV",
  },
  {
    name_en: "Croatia",
    name_ca: "Croàcia",
    name_es: "Croacia",
    dial_code: "+385",
    code: "HR",
    code_alpha_2: "HR",
    code_alpha_3: "HRV",
  },
  {
    name_en: "Cuba",
    name_ca: "Cuba",
    name_es: "Cuba",
    dial_code: "+53",
    code: "CU",
    code_alpha_2: "CU",
    code_alpha_3: "CUB",
  },
  {
    name_en: "Cyprus",
    name_ca: "Xipre",
    name_es: "Chipre",
    dial_code: "+537",
    code: "CY",
    code_alpha_2: "CY",
    code_alpha_3: "CYP",
  },
  {
    name_en: "Czech Republic",
    name_ca: "República Txeca",
    name_es: "Republica Checa ",
    dial_code: "+420",
    code: "CZ",
    code_alpha_2: "CZ",
    code_alpha_3: "CZE",
  },
  {
    name_en: "Denmark",
    name_ca: "Dinamarca",
    name_es: "Dinamarca",
    dial_code: "+45",
    code: "DK",
    code_alpha_2: "DK",
    code_alpha_3: "DNK",
  },
  {
    name_en: "Djibouti",
    name_ca: "Djibouti",
    name_es: " Yibuti",
    dial_code: "+253",
    code: "DJ",
    code_alpha_2: "DJ",
    code_alpha_3: "DJI",
  },
  {
    name_en: "Dominica",
    name_ca: "Dominique",
    name_es: "Dominica",
    dial_code: "+1 767",
    code: "DM",
    code_alpha_2: "DM",
    code_alpha_3: "DMA",
  },
  {
    name_en: "Dominican Republic",
    name_ca: "República Dominicana",
    name_es: "Republica Dominicana",
    dial_code: "+1 849",
    code: "DO",
    code_alpha_2: "DO",
    code_alpha_3: "DOM",
  },
  {
    name_en: "Ecuador",
    name_ca: "Equador",
    name_es: "Ecuador",
    dial_code: "+593",
    code: "EC",
    code_alpha_2: "EC",
    code_alpha_3: "ECU",
  },
  {
    name_en: "Egypt",
    name_ca: "Egipte",
    name_es: "Egipto",
    dial_code: "+20",
    code: "EG",
    code_alpha_2: "EG",
    code_alpha_3: "EGY",
  },
  {
    name_en: "El Salvador",
    name_ca: "El Salvador",
    name_es: "El Salvador",
    dial_code: "+503",
    code: "SV",
    code_alpha_2: "SV",
    code_alpha_3: "SLV",
  },
  {
    name_en: "Equatorial Guinea",
    name_ca: "Guinea Equatorial",
    name_es: "Guinea Ecuatorial",
    dial_code: "+240",
    code: "GQ",
    code_alpha_2: "GQ",
    code_alpha_3: "GNQ",
  },
  {
    name_en: "Eritrea",
    name_ca: "Eritrea",
    name_es: "Eritrea",
    dial_code: "+291",
    code: "ER",
    code_alpha_2: "ER",
    code_alpha_3: "ERI",
  },
  {
    name_en: "Estonia",
    name_ca: "Estònia",
    name_es: "Estonia",
    dial_code: "+372",
    code: "EE",
    code_alpha_2: "EE",
    code_alpha_3: "EST",
  },
  {
    name_en: "Ethiopia",
    name_ca: "Etiòpia",
    name_es: "Etiopia",
    dial_code: "+251",
    code: "ET",
    code_alpha_2: "ET",
    code_alpha_3: "ETH",
  },
  {
    name_en: "Falkland Islands (Malvinas)",
    name_ca: "Illes Malvines",
    name_es: "Islas Malvinas",
    dial_code: "+500",
    code: "FK",
    code_alpha_2: "FK",
    code_alpha_3: "FLK",
  },
  {
    name_en: "Faroe Islands",
    name_ca: "Illes Fèroe",
    name_es: "Islas Feroe",
    dial_code: "+298",
    code: "FO",
    code_alpha_2: "FO",
    code_alpha_3: "FRO",
  },
  {
    name_en: "Fiji",
    name_ca: "Fiji",
    name_es: "Fiyi",
    dial_code: "+679",
    code: "FJ",
    code_alpha_2: "FJ",
    code_alpha_3: "FJI",
  },
  {
    name_en: "Finland",
    name_ca: "Finlàndia",
    name_es: "Finlandia",
    dial_code: "+358",
    code: "FI",
    code_alpha_2: "FI",
    code_alpha_3: "FIN",
  },
  {
    name_en: "France",
    name_ca: "França",
    name_es: "Francia",
    dial_code: "+33",
    code: "FR",
    code_alpha_2: "FR",
    code_alpha_3: "FRA",
  },
  {
    name_en: "French Guiana",
    name_ca: "Guaiana Francesa",
    name_es: "Guayana Francesa",
    dial_code: "+594",
    code: "GF",
    code_alpha_2: "GF",
    code_alpha_3: "GUF",
  },
  {
    name_en: "French Polynesia",
    name_ca: "Polinèsia Francesa",
    name_es: "Polinesia Francesa",
    dial_code: "+689",
    code: "PF",
    code_alpha_2: "PF",
    code_alpha_3: "PYF",
  },
  {
    name_en: "Gabon",
    name_ca: "Gabon",
    name_es: "Gabon",
    dial_code: "+241",
    code: "GA",
    code_alpha_2: "GA",
    code_alpha_3: "GAB",
  },
  {
    name_en: "Gambia",
    name_ca: "Gàmbia",
    name_es: "Gambia",
    dial_code: "+220",
    code: "GM",
    code_alpha_2: "GM",
    code_alpha_3: "GMB",
  },
  {
    name_en: "Georgia",
    name_ca: "Georgia",
    name_es: "Georgia",
    dial_code: "+995",
    code: "GE",
    code_alpha_2: "GE",
    code_alpha_3: "GEO",
  },
  {
    name_en: "Germany",
    name_ca: "Alemanya",
    name_es: "Alemania",
    dial_code: "+49",
    code: "DE",
    code_alpha_2: "DE",
    code_alpha_3: "DEU",
  },
  {
    name_en: "Ghana",
    name_ca: "Ghana",
    name_es: "Ghana",
    dial_code: "+233",
    code: "GH",
    code_alpha_2: "GH",
    code_alpha_3: "GHA",
  },
  {
    name_en: "Gibraltar",
    name_ca: "Gibraltar",
    name_es: "Gibraltar",
    dial_code: "+350",
    code: "GI",
    code_alpha_2: "GI",
    code_alpha_3: "GIB",
  },
  {
    name_en: "Greece",
    name_ca: "Grècia",
    name_es: "Grecia",
    dial_code: "+30",
    code: "GR",
    code_alpha_2: "GR",
    code_alpha_3: "GRC",
  },
  {
    name_en: "Greenland",
    name_ca: "Groenlàndia",
    name_es: "Groenlandia",
    dial_code: "+299",
    code: "GL",
    code_alpha_2: "GL",
    code_alpha_3: "GRL",
  },
  {
    name_en: "Grenada",
    name_ca: "Granada",
    name_es: "Granada",
    dial_code: "+1 473",
    code: "GD",
    code_alpha_2: "GD",
    code_alpha_3: "GRD",
  },
  {
    name_en: "Guadeloupe",
    name_ca: "Guadalupe",
    name_es: "Guadalupe",
    dial_code: "+590",
    code: "GP",
    code_alpha_2: "GP",
    code_alpha_3: "GLP",
  },
  {
    name_en: "Guam",
    name_ca: "Guam",
    name_es: "Guam",
    dial_code: "+1 671",
    code: "GU",
    code_alpha_2: "GU",
    code_alpha_3: "GUM",
  },
  {
    name_en: "Guatemala",
    name_ca: "Guatemala",
    name_es: "Guatemala",
    dial_code: "+502",
    code: "GT",
    code_alpha_2: "GT",
    code_alpha_3: "GTM",
  },
  {
    name_en: "Guernsey",
    name_ca: "Guernsey",
    name_es: "Guernsey",
    dial_code: "+44",
    code: "GG",
    code_alpha_2: "GG",
    code_alpha_3: "GGY",
  },
  {
    name_en: "Guinea",
    name_ca: "Guinea",
    name_es: "Guinea",
    dial_code: "+224",
    code: "GN",
    code_alpha_2: "GN",
    code_alpha_3: "GIN",
  },
  {
    name_en: "Guinea-Bissau",
    name_ca: "Guinea Bisau",
    name_es: "Guinea-Bisau",
    dial_code: "+245",
    code: "GW",
    code_alpha_2: "GW",
    code_alpha_3: "GNB",
  },
  {
    name_en: "Guyana",
    name_ca: "Guaiana",
    name_es: "Guyana",
    dial_code: "+595",
    code: "GY",
    code_alpha_2: "GY",
    code_alpha_3: "GUY",
  },
  {
    name_en: "Haiti",
    name_ca: "Haiti",
    name_es: "Haiti",
    dial_code: "+509",
    code: "HT",
    code_alpha_2: "HT",
    code_alpha_3: "HTI",
  },
  {
    name_en: "Holy See (Vatican City State)",
    name_ca: "Ciutat de Vaticà",
    name_es: "Ciudad del Vaticano",
    dial_code: "+379",
    code: "VA",
    code_alpha_2: "VA",
    code_alpha_3: "VAT",
  },
  {
    name_en: "Honduras",
    name_ca: "Hondures",
    name_es: "Honduras",
    dial_code: "+504",
    code: "HN",
    code_alpha_2: "HN",
    code_alpha_3: "HND",
  },
  {
    name_en: "Hong Kong",
    name_ca: "Hong Kong",
    name_es: "Hong Kong",
    dial_code: "+852",
    code: "HK",
    code_alpha_2: "HK",
    code_alpha_3: "HKG",
  },
  {
    name_en: "Hungary",
    name_ca: "Hungria",
    name_es: "Hungria",
    dial_code: "+36",
    code: "HU",
    code_alpha_2: "HU",
    code_alpha_3: "HUN",
  },
  {
    name_en: "Iceland",
    name_ca: "Islàndia",
    name_es: "Islandia",
    dial_code: "+354",
    code: "IS",
    code_alpha_2: "IS",
    code_alpha_3: "ISL",
  },
  {
    name_en: "India",
    name_ca: "Índia",
    name_es: "India",
    dial_code: "+91",
    code: "IN",
    code_alpha_2: "IN",
    code_alpha_3: "IND",
  },
  {
    name_en: "Indonesia",
    name_ca: "Indonèsia",
    name_es: "Indonesia",
    dial_code: "+62",
    code: "ID",
    code_alpha_2: "ID",
    code_alpha_3: "IDN",
  },
  {
    name_en: "Iran, Islamic Republic of",
    name_ca: "Iran",
    name_es: "Iran",
    dial_code: "+98",
    code: "IR",
    code_alpha_2: "IR",
    code_alpha_3: "IRN",
  },
  {
    name_en: "Iraq",
    name_ca: "Iraq",
    name_es: "Iraq",
    dial_code: "+964",
    code: "IQ",
    code_alpha_2: "IQ",
    code_alpha_3: "IRQ",
  },
  {
    name_en: "Ireland",
    name_ca: "Irlanda",
    name_es: "Irlanda",
    dial_code: "+353",
    code: "IE",
    code_alpha_2: "IE",
    code_alpha_3: "IRL",
  },
  {
    name_en: "Isle of Man",
    name_ca: "Illa de Man",
    name_es: "Isla de Man",
    dial_code: "+44",
    code: "IM",
    code_alpha_2: "IM",
    code_alpha_3: "IMN",
  },
  {
    name_en: "Israel",
    name_ca: "Israel",
    name_es: "Israel",
    dial_code: "+972",
    code: "IL",
    code_alpha_2: "IL",
    code_alpha_3: "ISR",
  },
  {
    name_en: "Italy",
    name_ca: "Itàlia",
    name_es: "Italia",
    dial_code: "+39",
    code: "IT",
    code_alpha_2: "IT",
    code_alpha_3: "ITA",
  },
  {
    name_en: "Jamaica",
    name_ca: "Jamaica",
    name_es: "Jamaica",
    dial_code: "+1 876",
    code: "JM",
    code_alpha_2: "JM",
    code_alpha_3: "JAM",
  },
  {
    name_en: "Japan",
    name_ca: "Japó",
    name_es: "Japon",
    dial_code: "+81",
    code: "JP",
    code_alpha_2: "JP",
    code_alpha_3: "JPN",
  },
  {
    name_en: "Jersey",
    name_ca: "Jersey",
    name_es: "Jersey",
    dial_code: "+44",
    code: "JE",
    code_alpha_2: "JE",
    code_alpha_3: "JEY",
  },
  {
    name_en: "Jordan",
    name_ca: "Jordània",
    name_es: "Jordania",
    dial_code: "+962",
    code: "JO",
    code_alpha_2: "JO",
    code_alpha_3: "JOR",
  },
  {
    name_en: "Kazakhstan",
    name_ca: "Kazajistan",
    name_es: "Kazajistan",
    dial_code: "+7 7",
    code: "KZ",
    code_alpha_2: "KZ",
    code_alpha_3: "KAZ",
  },
  {
    name_en: "Kenya",
    name_ca: "Kenya",
    name_es: "Kenia",
    dial_code: "+254",
    code: "KE",
    code_alpha_2: "KE",
    code_alpha_3: "KEN",
  },
  {
    name_en: "Kiribati",
    name_ca: "Kiribati",
    name_es: "Kiribati",
    dial_code: "+686",
    code: "KI",
    code_alpha_2: "KI",
    code_alpha_3: "KIR",
  },
  {
    name_en: "Korea, Democratic People's Republic of",
    name_ca: "Corea del Nord",
    name_es: "Corea del Norte",
    dial_code: "+850",
    code: "KP",
    code_alpha_2: "KP",
    code_alpha_3: "PRK",
  },
  {
    name_en: "Korea, Republic of",
    name_ca: "Corea de Sud",
    name_es: "Corea del Sur",
    dial_code: "+82",
    code: "KR",
    code_alpha_2: "KR",
    code_alpha_3: "KOR",
  },
  {
    name_en: "Kuwait",
    name_ca: "Kuwait",
    name_es: "Kuwait",
    dial_code: "+965",
    code: "KW",
    code_alpha_2: "KW",
    code_alpha_3: "KWT",
  },
  {
    name_en: "Kyrgyzstan",
    name_ca: "Kirguistan",
    name_es: "Kirguistan",
    dial_code: "+996",
    code: "KG",
    code_alpha_2: "KG",
    code_alpha_3: "KGZ",
  },
  {
    name_en: "Lao People's Democratic Republic",
    name_ca: "Laos",
    name_es: "Laos",
    dial_code: "+856",
    code: "LA",
    code_alpha_2: "LA",
    code_alpha_3: "LAO",
  },
  {
    name_en: "Latvia",
    name_ca: "Letònia",
    name_es: "Letonia",
    dial_code: "+371",
    code: "LV",
    code_alpha_2: "LV",
    code_alpha_3: "LVA",
  },
  {
    name_en: "Lebanon",
    name_ca: "Liban",
    name_es: "Libano",
    dial_code: "+961",
    code: "LB",
    code_alpha_2: "LB",
    code_alpha_3: "LBN",
  },
  {
    name_en: "Lesotho",
    name_ca: "Lesotho",
    name_es: "Lesoto",
    dial_code: "+266",
    code: "LS",
    code_alpha_2: "LS",
    code_alpha_3: "LSO",
  },
  {
    name_en: "Liberia",
    name_ca: "Libèria",
    name_es: "Liberia",
    dial_code: "+231",
    code: "LR",
    code_alpha_2: "LR",
    code_alpha_3: "LBR",
  },
  {
    name_en: "Libyan Arab Jamahiriya",
    name_ca: "Líbia",
    name_es: "Libia",
    dial_code: "+218",
    code: "LY",
    code_alpha_2: "LY",
    code_alpha_3: "LBY",
  },
  {
    name_en: "Liechtenstein",
    name_ca: "Liechtenstein",
    name_es: "Liechtenstein",
    dial_code: "+423",
    code: "LI",
    code_alpha_2: "LI",
    code_alpha_3: "LIE",
  },
  {
    name_en: "Lithuania",
    name_ca: "Lituània",
    name_es: "Lituania",
    dial_code: "+370",
    code: "LT",
    code_alpha_2: "LT",
    code_alpha_3: "LTU",
  },
  {
    name_en: "Luxembourg",
    name_ca: "Luxemburg",
    name_es: "Luxemburgo",
    dial_code: "+352",
    code: "LU",
    code_alpha_2: "LU",
    code_alpha_3: "LUX",
  },
  {
    name_en: "Macao",
    name_ca: "Macau",
    name_es: "Macao",
    dial_code: "+853",
    code: "MO",
    code_alpha_2: "MO",
    code_alpha_3: "MAC",
  },
  {
    name_en: "Macedonia, The Former Yugoslav Republic of",
    name_ca: "República de Macedònia",
    name_es: "Republica de Macedonia",
    dial_code: "+389",
    code: "MK",
    code_alpha_2: "MK",
    code_alpha_3: "MKD",
  },
  {
    name_en: "Madagascar",
    name_ca: "Madagascar",
    name_es: "Madagascar",
    dial_code: "+261",
    code: "MG",
    code_alpha_2: "MG",
    code_alpha_3: "MDG",
  },
  {
    name_en: "Malawi",
    name_ca: "Malawi",
    name_es: "Malaui",
    dial_code: "+265",
    code: "MW",
    code_alpha_2: "MW",
    code_alpha_3: "MWI",
  },
  {
    name_en: "Malaysia",
    name_ca: "Malàisia",
    name_es: "Malasia",
    dial_code: "+60",
    code: "MY",
    code_alpha_2: "MY",
    code_alpha_3: "MYS",
  },
  {
    name_en: "Maldives",
    name_ca: "Maldives",
    name_es: "Maldivas",
    dial_code: "+960",
    code: "MV",
    code_alpha_2: "MV",
    code_alpha_3: "MDV",
  },
  {
    name_en: "Mali",
    name_ca: "Mali",
    name_es: "Mali",
    dial_code: "+223",
    code: "ML",
    code_alpha_2: "ML",
    code_alpha_3: "MLI",
  },
  {
    name_en: "Malta",
    name_ca: "Malta",
    name_es: "Malta",
    dial_code: "+356",
    code: "MT",
    code_alpha_2: "MT",
    code_alpha_3: "MLT",
  },
  {
    name_en: "Marshall Islands",
    name_ca: "Illes Marshall",
    name_es: "Islas Marshall",
    dial_code: "+692",
    code: "MH",
    code_alpha_2: "MH",
    code_alpha_3: "MHL",
  },
  {
    name_en: "Martinique",
    name_ca: "Martinica",
    name_es: "Martinica",
    dial_code: "+596",
    code: "MQ",
    code_alpha_2: "MQ",
    code_alpha_3: "MTQ",
  },
  {
    name_en: "Mauritania",
    name_ca: "Mauritània",
    name_es: "Mauritania",
    dial_code: "+222",
    code: "MR",
    code_alpha_2: "MR",
    code_alpha_3: "MRT",
  },
  {
    name_en: "Mauritius",
    name_ca: "Mauricio",
    name_es: "Mauricio",
    dial_code: "+230",
    code: "MU",
    code_alpha_2: "MU",
    code_alpha_3: "MUS",
  },
  {
    name_en: "Mayotte",
    name_ca: "Mayotte",
    name_es: "Mayotte",
    dial_code: "+262",
    code: "YT",
    code_alpha_2: "YT",
    code_alpha_3: "MYT",
  },
  {
    name_en: "Mexico",
    name_ca: "Mèxic",
    name_es: "Mexico",
    dial_code: "+52",
    code: "MX",
    code_alpha_2: "MX",
    code_alpha_3: "MEX",
  },
  {
    name_en: "Micronesia, Federated States of",
    name_ca: "Estats Federats de Micronèsia",
    name_es: "Estados Federados de Micronesia",
    dial_code: "+691",
    code: "FM",
    code_alpha_2: "FM",
    code_alpha_3: "FSM",
  },
  {
    name_en: "Moldova, Republic of",
    name_ca: "Moldàvia",
    name_es: "Moldavia",
    dial_code: "+373",
    code: "MD",
    code_alpha_2: "MD",
    code_alpha_3: "MDA",
  },
  {
    name_en: "Monaco",
    name_ca: "Monaco",
    name_es: "Monaco",
    dial_code: "+377",
    code: "MC",
    code_alpha_2: "MC",
    code_alpha_3: "MCO",
  },
  {
    name_en: "Mongolia",
    name_ca: "Mongòlia",
    name_es: "Mongolia",
    dial_code: "+976",
    code: "MN",
    code_alpha_2: "MN",
    code_alpha_3: "MNG",
  },
  {
    name_en: "Montenegro",
    name_ca: "Montenegro",
    name_es: "Montenegro",
    dial_code: "+382",
    code: "ME",
    code_alpha_2: "ME",
    code_alpha_3: "MNE",
  },
  {
    name_en: "Montserrat",
    name_ca: "Montserrat",
    name_es: "Montserrat",
    dial_code: "+1664",
    code: "MS",
    code_alpha_2: "MS",
    code_alpha_3: "MSR",
  },
  {
    name_en: "Morocco",
    name_ca: "El Marroc",
    name_es: "Marruecos",
    dial_code: "+212",
    code: "MA",
    code_alpha_2: "MA",
    code_alpha_3: "MAR",
  },
  {
    name_en: "Mozambique",
    name_ca: "Moçambic",
    name_es: "Mozambique",
    dial_code: "+258",
    code: "MZ",
    code_alpha_2: "MZ",
    code_alpha_3: "MOZ",
  },
  {
    name_en: "Myanmar",
    name_ca: "Birmània",
    name_es: "Birmania",
    dial_code: "+95",
    code: "MM",
    code_alpha_2: "MM",
    code_alpha_3: "MMR",
  },
  {
    name_en: "Namibia",
    name_ca: "Namíbia",
    name_es: "Namibia",
    dial_code: "+264",
    code: "NA",
    code_alpha_2: "NA",
    code_alpha_3: "NAM",
  },
  {
    name_en: "Nauru",
    name_ca: "Nauru",
    name_es: "Nauru",
    dial_code: "+674",
    code: "NR",
    code_alpha_2: "NR",
    code_alpha_3: "NRU",
  },
  {
    name_en: "Nepal",
    name_ca: "Nepal",
    name_es: "Nepal",
    dial_code: "+977",
    code: "NP",
    code_alpha_2: "NP",
    code_alpha_3: "NPL",
  },
  {
    name_en: "Netherlands",
    name_ca: "Holanda",
    name_es: "Holanda",
    dial_code: "+31",
    code: "NL",
    code_alpha_2: "NL",
    code_alpha_3: "NLD",
  },
  {
    name_en: "Netherlands Antilles",
    name_ca: "Antilles Holandeses",
    name_es: "Antillas Holandesas",
    dial_code: "+599",
    code: "AN",
    code_alpha_2: "AN",
    code_alpha_3: "ANT",
  },
  {
    name_en: "New Caledonia",
    name_ca: "Nova Caledònia",
    name_es: "Nueva Caledonia",
    dial_code: "+687",
    code: "NC",
    code_alpha_2: "NC",
    code_alpha_3: "NCL",
  },
  {
    name_en: "New Zealand",
    name_ca: "Nova Zelanda",
    name_es: "Nueva Zelanda",
    dial_code: "+64",
    code: "NZ",
    code_alpha_2: "NZ",
    code_alpha_3: "NZL",
  },
  {
    name_en: "Nicaragua",
    name_ca: "Nicaragua",
    name_es: "Nicaragua",
    dial_code: "+505",
    code: "NI",
    code_alpha_2: "NI",
    code_alpha_3: "NIC",
  },
  {
    name_en: "Niger",
    name_ca: "Níger",
    name_es: "Niger",
    dial_code: "+227",
    code: "NE",
    code_alpha_2: "NE",
    code_alpha_3: "NER",
  },
  {
    name_en: "Nigeria",
    name_ca: "Nigèria",
    name_es: "Nigeria",
    dial_code: "+234",
    code: "NG",
    code_alpha_2: "NG",
    code_alpha_3: "NGA",
  },
  {
    name_en: "Niue",
    name_ca: "Niue",
    name_es: "Niue",
    dial_code: "+683",
    code: "NU",
    code_alpha_2: "NU",
    code_alpha_3: "NIU",
  },
  {
    name_en: "Norfolk Island",
    name_ca: "Illa Norfolk",
    name_es: "Isla Norfolk",
    dial_code: "+672",
    code: "NF",
    code_alpha_2: "NF",
    code_alpha_3: "NFK",
  },
  {
    name_en: "Northern Mariana Islands",
    name_ca: "Illes Marianes de el Nord",
    name_es: "Islas Marianas del Norte",
    dial_code: "+1670",
    code: "MP",
    code_alpha_2: "MP",
    code_alpha_3: "MNP",
  },
  {
    name_en: "Norway",
    name_ca: "Noruega",
    name_es: "Noruega",
    dial_code: "+47",
    code: "NO",
    code_alpha_2: "NO",
    code_alpha_3: "NOR",
  },
  {
    name_en: "Oman",
    name_ca: "Oman",
    name_es: "Oman",
    dial_code: "+968",
    code: "OM",
    code_alpha_2: "OM",
    code_alpha_3: "OMN",
  },
  {
    name_en: "Pakistan",
    name_ca: "Pakistan",
    name_es: "Pakistan",
    dial_code: "+92",
    code: "PK",
    code_alpha_2: "PK",
    code_alpha_3: "PAK",
  },
  {
    name_en: "Palau",
    name_ca: "Palaos",
    name_es: "Palaos",
    dial_code: "+680",
    code: "PW",
    code_alpha_2: "PW",
    code_alpha_3: "PLW",
  },
  {
    name_en: "Palestinian Territory, Occupied",
    name_ca: "Territoris Palestins",
    name_es: "Territorios Palestinos",
    dial_code: "+970",
    code: "PS",
    code_alpha_2: "PS",
    code_alpha_3: "PSE",
  },
  {
    name_en: "Panama",
    name_ca: "Panama",
    name_es: "Panama",
    dial_code: "+507",
    code: "PA",
    code_alpha_2: "PA",
    code_alpha_3: "PAN",
  },
  {
    name_en: "Papua New Guinea",
    name_ca: "Papua Nova Guinea",
    name_es: "Papua Nueva Guinea",
    dial_code: "+675",
    code: "PG",
    code_alpha_2: "PG",
    code_alpha_3: "PNG",
  },
  {
    name_en: "Paraguay",
    name_ca: "Paraguai",
    name_es: "Paraguay",
    dial_code: "+595",
    code: "PY",
    code_alpha_2: "PY",
    code_alpha_3: "PRY",
  },
  {
    name_en: "Peru",
    name_ca: "Peru",
    name_es: "Peru",
    dial_code: "+51",
    code: "PE",
    code_alpha_2: "PE",
    code_alpha_3: "PER",
  },
  {
    name_en: "Philippines",
    name_ca: "Filipines",
    name_es: "Filipinas",
    dial_code: "+63",
    code: "PH",
    code_alpha_2: "PH",
    code_alpha_3: "PHL",
  },
  {
    name_en: "Pitcairn",
    name_ca: "Illes Pitcairn",
    name_es: "Islas Pitcairn",
    dial_code: "+872",
    code: "PN",
    code_alpha_2: "PN",
    code_alpha_3: "PCN",
  },
  {
    name_en: "Poland",
    name_ca: "Polònia",
    name_es: "Polonia",
    dial_code: "+48",
    code: "PL",
    code_alpha_2: "PL",
    code_alpha_3: "POL",
  },
  {
    name_en: "Portugal",
    name_ca: "Portugal",
    name_es: "Portugal",
    dial_code: "+351",
    code: "PT",
    code_alpha_2: "PT",
    code_alpha_3: "PRT",
  },
  {
    name_en: "Puerto Rico",
    name_ca: "Puerto Rico",
    name_es: "Puerto Rico",
    dial_code: "+1 939",
    code: "PR",
    code_alpha_2: "PR",
    code_alpha_3: "PRI",
  },
  {
    name_en: "Qatar",
    name_ca: "Qatar",
    name_es: "Qatar",
    dial_code: "+974",
    code: "QA",
    code_alpha_2: "QA",
    code_alpha_3: "QAT",
  },
  {
    name_en: "Romania",
    name_ca: "Romania",
    name_es: "Rumania",
    dial_code: "+40",
    code: "RO",
    code_alpha_2: "RO",
    code_alpha_3: "ROU",
  },
  {
    name_en: "Russia",
    name_ca: "Rússia",
    name_es: "Rusia",
    dial_code: "+7",
    code: "RU",
    code_alpha_2: "RU",
    code_alpha_3: "RUS",
  },
  {
    name_en: "Rwanda",
    name_ca: "Rwanda",
    name_es: "Ruanda",
    dial_code: "+250",
    code: "RW",
    code_alpha_2: "RW",
    code_alpha_3: "RWA",
  },
  {
    name_en: "Réunion",
    name_ca: "Reunion",
    name_es: "Reunion",
    dial_code: "+262",
    code: "RE",
    code_alpha_2: "RE",
    code_alpha_3: "REU",
  },
  {
    name_en: "Saint Barthélemy",
    name_ca: "San Bartolome",
    name_es: "San Bartolome",
    dial_code: "+590",
    code: "BL",
    code_alpha_2: "BL",
    code_alpha_3: "BLM",
  },
  {
    name_en: "Saint Helena, Ascension and Tristan Da Cunha",
    name_ca: "Santa Elena, Ascensió i Tristan da Cunha",
    name_es: "Santa Elena, Ascensión y Tristán de Acuña",
    dial_code: "+290",
    code: "SH",
    code_alpha_2: "SH",
    code_alpha_3: "SHN",
  },
  {
    name_en: "Saint Kitts and Nevis",
    name_ca: "San Cristóbal i Neus",
    name_es: "San Cristóbal y Nieves",
    dial_code: "+1 869",
    code: "KN",
    code_alpha_2: "KN",
    code_alpha_3: "KNA",
  },
  {
    name_en: "Saint Lucia",
    name_ca: "Santa Lucia",
    name_es: "Santa Lucia",
    dial_code: "+1 758",
    code: "LC",
    code_alpha_2: "LC",
    code_alpha_3: "LCA",
  },
  {
    name_en: "Saint Martin",
    name_ca: "Illa de Sant Martín",
    name_es: "Isla de San Martín",
    dial_code: "+590",
    code: "MF",
    code_alpha_2: "MF",
    code_alpha_3: "MAF",
  },
  {
    name_en: "Saint Pierre and Miquelon",
    name_ca: "Sant Pere i Miquelon",
    name_es: "San Pedro y Miquelon",
    dial_code: "+508",
    code: "PM",
    code_alpha_2: "PM",
    code_alpha_3: "SPM",
  },
  {
    name_en: "Saint Vincent and the Grenadines",
    name_ca: "Saint Vincent i les Grenadines",
    name_es: "San Vicente y las Granadinas",
    dial_code: "+1 784",
    code: "VC",
    code_alpha_2: "VC",
    code_alpha_3: "VCT",
  },
  {
    name_en: "Samoa",
    name_ca: "Samoa",
    name_es: "Samoa",
    dial_code: "+685",
    code: "WS",
    code_alpha_2: "WS",
    code_alpha_3: "WSM",
  },
  {
    name_en: "San Marino",
    name_ca: "San Marino",
    name_es: "San Marino",
    dial_code: "+378",
    code: "SM",
    code_alpha_2: "SM",
    code_alpha_3: "SMR",
  },
  {
    name_en: "Sao Tome and Principe",
    name_ca: "Sant Prengui i Principe",
    name_es: " Santo Tome y Principe ",
    dial_code: "+239",
    code: "ST",
    code_alpha_2: "ST",
    code_alpha_3: "STP",
  },
  {
    name_en: "Saudi Arabia",
    name_ca: "Aràbia Saudita",
    name_es: "Arabia Saudita",
    dial_code: "+966",
    code: "SA",
    code_alpha_2: "SA",
    code_alpha_3: "SAU",
  },
  {
    name_en: "Senegal",
    name_ca: "Senegal",
    name_es: "Senegal",
    dial_code: "+221",
    code: "SN",
    code_alpha_2: "SN",
    code_alpha_3: "SEN",
  },
  {
    name_en: "Serbia",
    name_ca: "Sèrbia",
    name_es: "Serbia",
    dial_code: "+381",
    code: "RS",
    code_alpha_2: "RS",
    code_alpha_3: "SRB",
  },
  {
    name_en: "Seychelles",
    name_ca: "Seychelles",
    name_es: "Seychelles",
    dial_code: "+248",
    code: "SC",
    code_alpha_2: "SC",
    code_alpha_3: "SYC",
  },
  {
    name_en: "Sierra Leone",
    name_ca: "Sierra Leone",
    name_es: "Sierra Leona",
    dial_code: "+232",
    code: "SL",
    code_alpha_2: "SL",
    code_alpha_3: "SLE",
  },
  {
    name_en: "Singapore",
    name_ca: "Singapur",
    name_es: "Singapur",
    dial_code: "+65",
    code: "SG",
    code_alpha_2: "SG",
    code_alpha_3: "SGP",
  },
  {
    name_en: "Slovakia",
    name_ca: "Eslovàquia",
    name_es: "Eslovaquia",
    dial_code: "+421",
    code: "SK",
    code_alpha_2: "SK",
    code_alpha_3: "SVK",
  },
  {
    name_en: "Slovenia",
    name_ca: "Eslovènia",
    name_es: "Eslovenia",
    dial_code: "+386",
    code: "SI",
    code_alpha_2: "SI",
    code_alpha_3: "SVN",
  },
  {
    name_en: "Solomon Islands",
    name_ca: "Illes Salomó",
    name_es: "Islas Salomón",
    dial_code: "+677",
    code: "SB",
    code_alpha_2: "SB",
    code_alpha_3: "SLB",
  },
  {
    name_en: "Somalia",
    name_ca: "Somàlia",
    name_es: "Somalia",
    dial_code: "+252",
    code: "SO",
    code_alpha_2: "SO",
    code_alpha_3: "SOM",
  },
  {
    name_en: "South Africa",
    name_ca: "Sud-àfrica",
    name_es: "Sudáfrica",
    dial_code: "+27",
    code: "ZA",
    code_alpha_2: "ZA",
    code_alpha_3: "ZAF",
  },
  {
    name_en: "South Georgia and the South Sandwich Islands",
    name_ca: "Illes Georgias de Sud i Sandvitx de Sud",
    name_es: "Islas Georgias del Sur y Sandwich del Sur",
    dial_code: "+500",
    code: "GS",
    code_alpha_2: "GS",
    code_alpha_3: "SGS",
  },
  {
    name_en: "Spain",
    name_ca: "Espanya",
    name_es: "España",
    dial_code: "+34",
    code: "ES",
    code_alpha_2: "ES",
    code_alpha_3: "ESP",
  },
  {
    name_en: "Sri Lanka",
    name_ca: "Sri Lanka",
    name_es: "Sri Lanka",
    dial_code: "+94",
    code: "LK",
    code_alpha_2: "LK",
    code_alpha_3: "LKA",
  },
  {
    name_en: "Sudan",
    name_ca: "Sudan",
    name_es: "Sudan",
    dial_code: "+249",
    code: "SD",
    code_alpha_2: "SD",
    code_alpha_3: "SDN",
  },
  {
    name_en: "Suriname",
    name_ca: "Surinam",
    name_es: "Surinam ",
    dial_code: "+597",
    code: "SR",
    code_alpha_2: "SR",
    code_alpha_3: "SUR",
  },
  {
    name_en: "Svalbard and Jan Mayen",
    name_ca: "Svalbard i Jan Mayen",
    name_es: "Svalbard y Jan Mayen",
    dial_code: "+47",
    code: "SJ",
    code_alpha_2: "SJ",
    code_alpha_3: "SJM",
  },
  {
    name_en: "Swaziland",
    name_ca: "Swazilàndia",
    name_es: "Suazilandia",
    dial_code: "+268",
    code: "SZ",
    code_alpha_2: "SZ",
    code_alpha_3: "SWZ",
  },
  {
    name_en: "Sweden",
    name_ca: "Suècia",
    name_es: "Suecia",
    dial_code: "+46",
    code: "SE",
    code_alpha_2: "SE",
    code_alpha_3: "SWE",
  },
  {
    name_en: "Switzerland",
    name_ca: "Suïssa",
    name_es: "Suiza",
    dial_code: "+41",
    code: "CH",
    code_alpha_2: "CH",
    code_alpha_3: "CHE",
  },
  {
    name_en: "Syrian Arab Republic",
    name_ca: "Síria",
    name_es: "Siria",
    dial_code: "+963",
    code: "SY",
    code_alpha_2: "SY",
    code_alpha_3: "SYR",
  },
  {
    name_en: "Taiwan, Province of China",
    name_ca: "Taiwan",
    name_es: "Taiwan",
    dial_code: "+886",
    code: "TW",
    code_alpha_2: "TW",
    code_alpha_3: "TWN",
  },
  {
    name_en: "Tajikistan",
    name_ca: "Tayikistan",
    name_es: "Tayikistan",
    dial_code: "+992",
    code: "TJ",
    code_alpha_2: "TJ",
    code_alpha_3: "TJK",
  },
  {
    name_en: "Tanzania, United Republic of",
    name_ca: "Tanzània",
    name_es: "Tanzania",
    dial_code: "+255",
    code: "TZ",
    code_alpha_2: "TZ",
    code_alpha_3: "TZA",
  },
  {
    name_en: "Thailand",
    name_ca: "Tailàndia",
    name_es: "Tailandia",
    dial_code: "+66",
    code: "TH",
    code_alpha_2: "TH",
    code_alpha_3: "THA",
  },
  {
    name_en: "Timor-Leste",
    name_ca: "Timor Oriental",
    name_es: "Timor Oriental",
    dial_code: "+670",
    code: "TL",
    code_alpha_2: "TL",
    code_alpha_3: "TLS",
  },
  {
    name_en: "Togo",
    name_ca: "Togo",
    name_es: "Togo",
    dial_code: "+228",
    code: "TG",
    code_alpha_2: "TG",
    code_alpha_3: "TGO",
  },
  {
    name_en: "Tokelau",
    name_ca: "Tokelau",
    name_es: "Tokelau",
    dial_code: "+690",
    code: "TK",
    code_alpha_2: "TK",
    code_alpha_3: "TKL",
  },
  {
    name_en: "Tonga",
    name_ca: "Tonga",
    name_es: "Tonga",
    dial_code: "+676",
    code: "TO",
    code_alpha_2: "TO",
    code_alpha_3: "TON",
  },
  {
    name_en: "Trinidad and Tobago",
    name_ca: "Trinitat i Tobago",
    name_es: "Trinidad y Tobago",
    dial_code: "+1 868",
    code: "TT",
    code_alpha_2: "TT",
    code_alpha_3: "TTO",
  },
  {
    name_en: "Tunisia",
    name_ca: "Tunísia",
    name_es: "Tunez",
    dial_code: "+216",
    code: "TN",
    code_alpha_2: "TN",
    code_alpha_3: "TUN",
  },
  {
    name_en: "Turkey",
    name_ca: "Turquia",
    name_es: "Turquia",
    dial_code: "+90",
    code: "TR",
    code_alpha_2: "TR",
    code_alpha_3: "TUR",
  },
  {
    name_en: "Turkmenistan",
    name_ca: "Turkmenistan",
    name_es: "Turkmenistan",
    dial_code: "+993",
    code: "TM",
    code_alpha_2: "TM",
    code_alpha_3: "TKM",
  },
  {
    name_en: "Turks and Caicos Islands",
    name_ca: "Illes Turks i Caicos",
    name_es: "Islas Turcas y Caicos",
    dial_code: "+1 649",
    code: "TC",
    code_alpha_2: "TC",
    code_alpha_3: "TCA",
  },
  {
    name_en: "Tuvalu",
    name_ca: "Tuvalu",
    name_es: "Tuvalu",
    dial_code: "+688",
    code: "TV",
    code_alpha_2: "TV",
    code_alpha_3: "TUV",
  },
  {
    name_en: "Uganda",
    name_ca: "Uganda",
    name_es: "Uganda",
    dial_code: "+256",
    code: "UG",
    code_alpha_2: "UG",
    code_alpha_3: "UGA",
  },
  {
    name_en: "Ukraine",
    name_ca: "Ucraïna",
    name_es: "Ucrania",
    dial_code: "+380",
    code: "UA",
    code_alpha_2: "UA",
    code_alpha_3: "UKR",
  },
  {
    name_en: "United Arab Emirates",
    name_ca: "Emirats Àrabs Units",
    name_es: "Emiratos Arabes Unidos",
    dial_code: "+971",
    code: "AE",
    code_alpha_2: "AE",
    code_alpha_3: "ARE",
  },
  {
    name_en: "United Kingdom",
    name_ca: "Regne Unit",
    name_es: "Reino Unido",
    dial_code: "+44",
    code: "GB",
    code_alpha_2: "GB",
    code_alpha_3: "GBR",
  },
  {
    name_en: "United States",
    name_ca: "Estats Units",
    name_es: "Estados Unidos",
    dial_code: "+1",
    code: "US",
    code_alpha_2: "US",
    code_alpha_3: "USA",
  },
  {
    name_en: "Uruguay",
    name_ca: "Uruguai",
    name_es: "Uruguay",
    dial_code: "+598",
    code: "UY",
    code_alpha_2: "UY",
    code_alpha_3: "URY",
  },
  {
    name_en: "Uzbekistan",
    name_ca: "Uzbekistan",
    name_es: "Uzbekistan",
    dial_code: "+998",
    code: "UZ",
    code_alpha_2: "UZ",
    code_alpha_3: "UZB",
  },
  {
    name_en: "Vanuatu",
    name_ca: "Vanuatu",
    name_es: "Vanuatu",
    dial_code: "+678",
    code: "VU",
    code_alpha_2: "VU",
    code_alpha_3: "VUT",
  },
  {
    name_en: "Venezuela, Bolivarian Republic of",
    name_ca: "Veneçuela",
    name_es: "Venezuela",
    dial_code: "+58",
    code: "VE",
    code_alpha_2: "VE",
    code_alpha_3: "VEN",
  },
  {
    name_en: "Viet Nam",
    name_ca: "Vietnam",
    name_es: "Viet Nam",
    dial_code: "+84",
    code: "VN",
    code_alpha_2: "VN",
    code_alpha_3: "VNM",
  },
  {
    name_en: "Virgin Islands, British",
    name_ca: "Illes Verges Britàniques",
    name_es: "Islas Vírgenes Británicas",
    dial_code: "+1 284",
    code: "VG",
    code_alpha_2: "VG",
    code_alpha_3: "VGB",
  },
  {
    name_en: "Virgin Islands, U.S.",
    name_ca: "Illes Verges dels Estats Units",
    name_es: "Islas Vírgenes de los Estados Unidos",
    dial_code: "+1 340",
    code: "VI",
    code_alpha_2: "VI",
    code_alpha_3: "VIR",
  },
  {
    name_en: "Wallis and Futuna",
    name_ca: "Wallis i Futuna",
    name_es: "Wallis y Futuna",
    dial_code: "+681",
    code: "WF",
    code_alpha_2: "WF",
    code_alpha_3: "WLF",
  },
  {
    name_en: "Yemen",
    name_ca: "Iemen",
    name_es: "Yemen",
    dial_code: "+967",
    code: "YE",
    code_alpha_2: "YE",
    code_alpha_3: "YEM",
  },
  {
    name_en: "Zambia",
    name_ca: "Zàmbia",
    name_es: "Zambia",
    dial_code: "+260",
    code: "ZM",
    code_alpha_2: "ZM",
    code_alpha_3: "ZMB",
  },
  {
    name_en: "Zimbabwe",
    name_ca: "Zimbabwe",
    name_es: " Zimbabue",
    dial_code: "+263",
    code: "ZW",
    code_alpha_2: "ZW",
    code_alpha_3: "ZWE",
  },
  {
    name_en: "Åland Islands",
    name_ca: "Åland",
    name_es: "Åland",
    dial_code: "+358",
    code: "AX",
    code_alpha_2: "AX",
    code_alpha_3: "ALA",
  },
];
