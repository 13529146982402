import Vue from "vue";
import i18n from "@/libs/i18n";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import countries from "@/utils/countries";
import {
  formatCurrency,
  formatDateObjectToHiTime,
  formatDateObjectToYmdDate,
  formatDateStringToDate,
  formatDateStringToDatabaseDate,
} from "./formatters";
import { uncalculableFrequencies } from "./consts";
import { TPV_BASE_LINK } from "@appConfig";
const Diacritics = require("diacritic");

/** **************** */
/** **************** */
/* PRIVATE FUNCTIONS */
/** **************** */
/** **************** */

function calculateDateDiff(date1, date2) {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  const utcd1 = Date.UTC(d1.getFullYear(), d1.getMonth(), d1.getDate());
  const utcd2 = Date.UTC(d2.getFullYear(), d2.getMonth(), d2.getDate());
  return Math.floor((utcd2 - utcd1) / (1000 * 60 * 60 * 24));
}

function calculateBookingServiceOwnerBasePrice(bookingService) {
  // If the cost price is not defined, return the pvp price by default
  let basePrice = bookingService.pvpPrice;

  if (bookingService.costPrice || bookingService.costPrice === 0) {
    basePrice = bookingService.costPrice;
  }

  return basePrice;
}

function simplifyLocale(locale) {
  if (!locale) return null;
  return locale.split("-")[0].toLowerCase();
}

/** ****** */
/** ****** */
/* EXPORTS */
/** ****** */
/** ****** */

export const copyToClipboard = (text) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      notifySuccess(
        "Copiat!",
        "S'ha copiat el text correctament al porta-retalls"
      );
      // You can also provide user-friendly feedback here.
    })
    .catch((error) => {
      notifyError(
        "Error",
        `Hi ha hagut un problema al intentar copiar el text al porta-retalls: ${error}`
      );
      // You can display an error message to the user if needed.
    });
};

export const removeHtmlTags = (text) => {
  if (typeof text !== "string") return null;
  return text.replace(/(<([^>]+)>)/gi, " ");
};

export const isEmpty = (data) => {
  if (typeof data === "string") {
    return data.trim().length === 0;
  }
  if (typeof data === "number" || typeof data === "boolean") {
    return false;
  }
  if (typeof data === "undefined" || data === null) {
    return true;
  }
  if (typeof data.length !== "undefined") {
    return data.length === 0;
  }

  // let count = 0
  // for (const i in data) {
  //   if (data.hasOwnProperty(i)) {
  //     count++
  //   }
  // }
  // return count == 0
  return false;
};

export const notifyError = (title, text, timeout = 6000) => {
  Vue.$toast(
    {
      component: ToastificationContent,
      props: {
        title,
        icon: "AlertTriangleIcon",
        text,
        variant: "danger",
      },
    },
    {
      timeout,
    }
  );
};

export const notifySuccess = (title, text, timeout = 6000) => {
  Vue.$toast(
    {
      component: ToastificationContent,
      props: {
        title,
        icon: "CheckCircleIcon",
        text,
        variant: "success",
      },
    },
    {
      timeout,
    }
  );
};

export const getCountryName = (countryCode, locale = "ca-ES") => {
  if (!countryCode) {
    return null;
  }

  const country = countries.find((c) => c.code === countryCode);
  if (country) {
    switch (simplifyLocale(locale)) {
      case "ca":
        return country.name_ca;
      case "es":
        return country.name_es;
      case "en":
        return country.name_en;
      default:
        return countryCode;
    }
  }
  return countryCode;
};
export const getCountryCode = (countryCode) => {
  return getAlpha2CountryCode(countryCode);
};

export const getAlpha2CountryCode = (countryCode) => {
  if (!countryCode) return null;
  const country = countries.find((c) => c.code === countryCode);
  return country?.code_alpha_2 || null;
};

export const getAlpha3CountryCode = (countryCode) => {
  if (!countryCode) return null;
  if (countryCode.length === 3) return countryCode;
  const country = countries.find((c) => c.code === countryCode);
  return country?.code_alpha_3 || null;
};

export const getCountryDialCode = (countryCode) => {
  if (!countryCode) return null;
  const country = countries.find((c) => c.code === countryCode);
  return country?.dial_code || null;
};

export const getAccommodationTypeName = (
  accommodationTypeCode,
  locale = "ca-ES"
) => {
  if (!accommodationTypeCode) return null;
  switch (simplifyLocale(locale)) {
    case "ca":
      switch (accommodationTypeCode.toUpperCase()) {
        case "VILLA":
          return "Villa";
        case "CHALET":
          return "Xalet";
        case "TOWN_HOUSE":
          return "Casa de poble";
        case "COUNTRY_HOUSE":
          return "Casa de camp";
        case "APARTMENT":
          return "Apartament";
        case "PLOT":
          return "Solar";
        case "BUILDABLE_RUSTIC_LAND":
          return "Finca rústica (edificable)";
        case "NON_BUILDABLE_RUSTIC_LAND":
          return "Finca rústica (no edificable)";
        case "OTHER":
          return "Altre";
        default:
          return "Allotjament";
      }
    case "es":
      switch (accommodationTypeCode.toUpperCase()) {
        case "VILLA":
          return "Villa";
        case "CHALET":
          return "Chalet";
        case "TOWN_HOUSE":
          return "Casa de pueblo";
        case "COUNTRY_HOUSE":
          return "Casa de campo";
        case "APARTMENT":
          return "Apartamento";
        case "PLOT":
          return "Solar";
        case "BUILDABLE_RUSTIC_LAND":
          return "Finca rústica (edificable)";
        case "NON_BUILDABLE_RUSTIC_LAND":
          return "Finca rústica (no edificable)";
        case "OTHER":
          return "Otro";
        default:
          return "Alojamiento";
      }
    case "en":
      switch (accommodationTypeCode.toUpperCase()) {
        case "VILLA":
          return "Villa";
        case "CHALET":
          return "Chalet";
        case "TOWN_HOUSE":
          return "Town house";
        case "COUNTRY_HOUSE":
          return "Country house";
        case "APARTMENT":
          return "Apartment";
        case "PLOT":
          return "Plot";
        case "BUILDABLE_RUSTIC_LAND":
          return "Rustic property (buildable)";
        case "NON_BUILDABLE_RUSTIC_LAND":
          return "Rustic property (not buildable)";
        case "OTHER":
          return "Other";
        default:
          return "Accommodation";
      }
    case "de":
      switch (accommodationTypeCode.toUpperCase()) {
        case "VILLA":
          return "Villa";
        case "CHALET":
          return "Chalet";
        case "TOWN_HOUSE":
          return "Stadthaus";
        case "COUNTRY_HOUSE":
          return "Landhaus";
        case "APARTMENT":
          return "Wohnung";
        case "PLOT":
          return "Parzelle";
        case "BUILDABLE_RUSTIC_LAND":
          return "Rustikales Anwesen (bebaubar)";
        case "NON_BUILDABLE_RUSTIC_LAND":
          return "Rustikales Anwesen (nicht bebaubar)";
        case "OTHER":
          return "Andere";
        default:
          return "Unterkunft";
      }
    default:
      switch (accommodationTypeCode.toUpperCase()) {
        case "VILLA":
          return "Villa";
        case "CHALET":
          return "Chalet";
        case "TOWN_HOUSE":
          return "Town house";
        case "COUNTRY_HOUSE":
          return "Country house";
        case "APARTMENT":
          return "Apartment";
        case "PLOT":
          return "Plot";
        case "BUILDABLE_RUSTIC_LAND":
          return "Rustic property (buildable)";
        case "NON_BUILDABLE_RUSTIC_LAND":
          return "Rustic property (not buildable)";
        case "OTHER":
          return "Other";
        default:
          return "Accommodation";
      }
  }
};

// eslint-disable-next-line no-unused-vars
export const getAddressTypeName = (addressTypeCode, locale = "ca-ES") => {
  if (!addressTypeCode) return null;
  switch (addressTypeCode) {
    case "STREET":
      return "Carrer";
    case "AVENUE":
      return "Avinguda";
    case "PATH":
    case "PATHWAY":
      return "Camí";
    case "ROAD":
      return "Carretera";
    case "DISSEMINATED":
      return "Disseminat";
    case "WAY":
      return "Via";
    case "FREEWAY":
      return "Autovia";
    case "SQUARE":
      return "Plaça";
    case "BOULEVARD":
      return "Boulevard";
    case "COURT":
      return "Pati";
    case "PASSAGE":
      return "Passatge";
    case "PROMENADE":
      return "Passeig";
    case "RAMBLA":
      return "Rambla";
    case "OTHER":
      return "Altres";
    default:
      return addressTypeCode;
  }
};

export const getFullAddress = (
  location,
  locale = "ca-ES",
  city = true,
  region = true,
  country = true
) => {
  if (!location) return null;

  const address = [];

  if (
    (location.addressType && location.addressType !== "DISSEMINATED") ||
    location.addressName
  ) {
    const addressName = [];
    if (!isEmpty(location.addressType)) {
      addressName.push(getAddressTypeName(location.addressType, locale));
    }
    if (!isEmpty(location.addressName)) {
      addressName.push(location.addressName);
    }
    if (!isEmpty(location.addressNumber)) {
      addressName.push(location.addressNumber);
    }
    if (addressName.length > 0) address.push(addressName.join(" "));
  }

  switch (locale.toLowerCase()) {
    case "ca-es":
      if (!isEmpty(location.addressBlock)) {
        address.push(`Bloc ${location.addressBlock}`);
      }
      if (!isEmpty(location.addressPortal)) {
        address.push(`Portal ${location.addressPortal}`);
      }
      if (!isEmpty(location.addressStairs)) {
        address.push(`Escala ${location.addressStairs}`);
      }
      if (!isEmpty(location.addressFloor)) {
        address.push(`Pis ${location.addressFloor}`);
      }
      if (!isEmpty(location.addressDoor)) {
        address.push(`Porta ${location.addressDoor}`);
      }
      if (!isEmpty(location.addressPolygon)) {
        address.push(`Polígon ${location.addressPolygon}`);
      }
      if (!isEmpty(location.addressPlot)) {
        address.push(`Parcel·la ${location.addressPlot}`);
      }
      break;
    case "es-es":
      if (!isEmpty(location.addressBlock)) {
        address.push(`Bloque ${location.addressBlock}`);
      }
      if (!isEmpty(location.addressPortal)) {
        address.push(`Portal ${location.addressPortal}`);
      }
      if (!isEmpty(location.addressStairs)) {
        address.push(`Escalera ${location.addressStairs}`);
      }
      if (!isEmpty(location.addressFloor)) {
        address.push(`Piso ${location.addressFloor}`);
      }
      if (!isEmpty(location.addressDoor)) {
        address.push(`Puerta ${location.addressDoor}`);
      }
      if (!isEmpty(location.addressPolygon)) {
        address.push(`Polígono ${location.addressPolygon}`);
      }
      if (!isEmpty(location.addressPlot)) {
        address.push(`Parcela ${location.addressPlot}`);
      }
      break;
    case "en-gb":
    default:
      if (!isEmpty(location.addressBlock)) {
        address.push(`Block ${location.addressBlock}`);
      }
      if (!isEmpty(location.addressPortal)) {
        address.push(`Portal ${location.addressPortal}`);
      }
      if (!isEmpty(location.addressStairs)) {
        address.push(`Stairs ${location.addressStairs}`);
      }
      if (!isEmpty(location.addressFloor)) {
        address.push(`Floor ${location.addressFloor}`);
      }
      if (!isEmpty(location.addressDoor)) {
        address.push(`Door ${location.addressDoor}`);
      }
      if (!isEmpty(location.addressPolygon)) {
        address.push(`Polygon ${location.addressPolygon}`);
      }
      if (!isEmpty(location.addressPlot)) {
        address.push(`Plot ${location.addressPlot}`);
      }
      break;
  }

  if (city && (location.zip || location.city)) {
    const addressCity = [];
    if (!isEmpty(location.zip)) addressCity.push(location.zip);
    if (!isEmpty(location.city)) addressCity.push(location.city);
    if (addressCity.length > 0) address.push(addressCity.join(" "));
  }

  if (region && !isEmpty(location.region)) address.push(location.region);
  if (country && !isEmpty(location.country)) {
    address.push(getCountryName(location.country, locale));
  }

  return address ? address.join(", ") : null;
};

export const getAreaName = (areaCode, locale = "ca-ES") => {
  if (!areaCode) return null;
  switch (simplifyLocale(locale)) {
    case "ca":
      switch (areaCode.toUpperCase()) {
        case "NORTH":
          return "Nord";
        case "SOUTH":
          return "Sud";
        case "EAST":
          return "Est";
        case "WEST":
          return "Oest";
        case "CENTER":
          return "Centre";
        case "NORTH_EAST":
        case "NORTH-EAST":
          return "Nord-Est";
        case "NORTH_WEST":
        case "NORTH-WEST":
          return "Nord-Oest";
        case "SOUTH_EAST":
        case "SOUTH-EAST":
          return "Sud-Est";
        case "SOUTH_WEST":
        case "SOUTH-WEST":
          return "Sud-Oest";
        case "CENTER_NORTH":
        case "CENTER-NORTH":
          return "Centre-Nord";
        case "CENTER_SOUTH":
        case "CENTER-SOUTH":
          return "Centre-Sud";
        case "CENTER_EAST":
        case "CENTER-EAST":
          return "Centre-Est";
        case "CENTER_WEST":
        case "CENTER-WEST":
          return "Centre-Oest";
        default:
          return areaCode;
      }
    case "es":
      switch (areaCode.toUpperCase()) {
        case "NORTH":
          return "Norte";
        case "SOUTH":
          return "Sur";
        case "EAST":
          return "Este";
        case "WEST":
          return "Oeste";
        case "CENTER":
          return "Centro";
        case "NORTH_EAST":
        case "NORTH-EAST":
          return "Noreste";
        case "NORTH_WEST":
        case "NORTH-WEST":
          return "Noroeste";
        case "SOUTH_EAST":
        case "SOUTH-EAST":
          return "Sureste";
        case "SOUTH_WEST":
        case "SOUTH-WEST":
          return "Suroeste";
        case "CENTER_NORTH":
        case "CENTER-NORTH":
          return "Centro-Norte";
        case "CENTER_SOUTH":
        case "CENTER-SOUTH":
          return "Centro-Sur";
        case "CENTER_EAST":
        case "CENTER-EAST":
          return "Centro-Este";
        case "CENTER_WEST":
        case "CENTER-WEST":
          return "Centro-Oeste";
        default:
          return areaCode;
      }
    case "en":
    default:
      switch (areaCode.toUpperCase()) {
        case "NORTH":
          return "Nord";
        case "SOUTH":
          return "South";
        case "EAST":
          return "East";
        case "WEST":
          return "West";
        case "CENTER":
          return "Center";
        case "NORTH_EAST":
        case "NORTH-EAST":
          return "Northeast";
        case "NORTH_WEST":
        case "NORTH-WEST":
          return "Northwest";
        case "SOUTH_EAST":
        case "SOUTH-EAST":
          return "Southeast";
        case "SOUTH_WEST":
        case "SOUTH-WEST":
          return "Southwest";
        case "CENTER_NORTH":
        case "CENTER-NORTH":
          return "Center-North";
        case "CENTER_SOUTH":
        case "CENTER-SOUTH":
          return "Center-South";
        case "CENTER_EAST":
        case "CENTER-EAST":
          return "Center-East";
        case "CENTER_WEST":
        case "CENTER-WEST":
          return "Center-West";
        default:
          return areaCode;
      }
  }
};

// eslint-disable-next-line no-unused-vars
export const getViewName = (viewCode, locale = "ca-ES") => {
  if (!viewCode) return null;
  switch (viewCode) {
    case "NONE":
      return "Sense vistes";
    case "GARDEN":
      return "Jardí";
    case "POOL":
      return "Piscina";
    case "BEACH":
      return "Platja";
    case "OCEAN":
      return "Mar";
    case "MOUNTAIN":
      return "Muntanya";
    case "TERRACE":
      return "Terrassa";
    case "STREET":
      return "Carrer";
    default:
      return viewCode;
  }
};

// eslint-disable-next-line no-unused-vars
export const getHeatingName = (heatingCode, locale = "ca-ES") => {
  if (!heatingCode) return null;
  switch (heatingCode) {
    case "AIR_CONDITIONING":
      return "Sí, A/C";
    case "ELECTRIC_RADIATORS":
      return "Sí, radiadors elèctrics";
    case "OIL_RADIATORS":
      return "Sí, radiadors d'oli";
    case "WATER_RADIATORS":
      return "Sí, radiadors d'aigua";
    case "FLOOR":
      return "Sí, sòl radiant";
    case "MIXED":
      return "Sí, mixte";
    default:
      return heatingCode;
  }
};

// eslint-disable-next-line no-unused-vars
export const getBedTypeName = (bedTypeCode, locale = "ca-ES") => {
  if (!bedTypeCode) return null;
  switch (bedTypeCode) {
    case "SINGLE":
      return "Llit individual";
    case "DOUBLE":
      return "Llit doble";
    case "SINGLE_BUNK_BED":
      return "Llitera individual";
    case "DOUBLE_BUNK_BED":
      return "Llitera doble";
    case "MIXED_BUNK_BED":
      return "Llitera mixta";
    case "SINGLE_SOFA_BED":
      return "Sofà llit individual";
    case "DOUBLE_SOFA_BED":
      return "Sofà llit doble";
    default:
      return bedTypeCode;
  }
};

// eslint-disable-next-line no-unused-vars
export const getMattressTypeName = (mattressTypeCode, locale = "ca-ES") => {
  if (!mattressTypeCode) return null;
  switch (mattressTypeCode) {
    case "SPRINGS":
      return "Matalàs de molles";
    case "FOAM":
      return "Matalàs d'escuma";
    case "LATEX":
      return "Matalàs de latex";
    case "WATER":
      return "Matalàs d'aigua";
    case "AIR":
      return "Matalàs d'aire";
    case "MIXED":
      return "Matalàs mixte";
    default:
      return mattressTypeCode;
  }
};

// eslint-disable-next-line no-unused-vars
export const getPillowTypeName = (pillowTypeCode, locale = "ca-ES") => {
  if (!pillowTypeCode) return null;
  switch (pillowTypeCode) {
    case "FOAM":
      return "Coixí d'escuma";
    case "LATEX":
      return "Coixí de latex";
    case "FEATHERS":
      return "Coixí de plomes";
    default:
      return pillowTypeCode;
  }
};

// eslint-disable-next-line no-unused-vars
export const getBedDescriptionText = (bed, locale = "ca-ES") => {
  const text = [];
  if (bed.width && bed.length) {
    text.push(`${bed.width} x ${bed.length} cm`);
  }
  if (bed.mattressType) {
    text.push(getMattressTypeName(bed.mattressType));
  }
  if (bed.pillowType) {
    text.push(getPillowTypeName(bed.pillowType));
  }
  return text.join(" · ");
};

// eslint-disable-next-line no-unused-vars
export const getBedroomTypeName = (bedroomTypeCode, locale = "ca-ES") => {
  if (!bedroomTypeCode) return null;
  switch (bedroomTypeCode) {
    case "MASTER":
      return "Principal";
    case "STANDARD":
      return "Estàndard";
    case "COMUNAL":
      return "Zona comuna";
    case "OPEN":
      return "Obert";
    default:
      return bedroomTypeCode;
  }
};

// eslint-disable-next-line no-unused-vars
export const getBedroomAmenitiesText = (bedroom, locale = "ca-ES") => {
  const amenities = [];
  if (bedroom.wardrobe) amenities.push("Armari");
  if (bedroom.tv) amenities.push("TV");
  if (bedroom.smartTv) amenities.push("Smart TV");
  if (bedroom.blinds) amenities.push("Persianes");
  if (bedroom.curtains) amenities.push("Cortines");
  if (bedroom.balcony) amenities.push("Balcó");
  if (bedroom.mosquitoNet) amenities.push("Mosquitera");
  if (bedroom.wifiCoverage) {
    if (bedroom.wifiConnectionSpeed)
      amenities.push(`Cobertura WiFi (${bedroom.wifiConnectionSpeed} Mb/s)`);
    else amenities.push("Cobertura WiFi");
  }
  if (bedroom.noiseLevel)
    amenities.push(`Renou ambiental (${bedroom.noiseLevel} dB)`);
  if (bedroom.disabledAdapted) amenities.push("Adaptat");
  return amenities.length > 0 ? amenities.join(" · ") : null;
};

// eslint-disable-next-line no-unused-vars
export const getEnsuiteBathroomText = (bedroom, bathrooms) => {
  if (bedroom.bathroom) {
    const text = [];
    text.push("Sí");
    let alreadyAssignedBathroom = null;
    if (bathrooms && bathrooms.length > 0) {
      bathrooms.forEach((bathroom, index) => {
        if (!alreadyAssignedBathroom && bathroom["@id"] === bedroom.bathroom) {
          alreadyAssignedBathroom = index + 1;
        }
      });
    }
    if (alreadyAssignedBathroom) {
      text.push(`bany ${alreadyAssignedBathroom}`);
    }
    return text.join(", ");
  }
  return null;
};

// eslint-disable-next-line no-unused-vars
export const getFloorName = (floor, locale = "ca-ES") => {
  if (typeof floor !== "number") return null;
  if (floor === 0) return "Planta baixa";
  if (floor > 0) return `${floor}ª planta`;
  return "Soterrani";
};

export const getAmenityTextAndUnit = (amenity) => {
  if (!amenity?.value || !amenity?.units) return null;

  return `${amenity.value} ${amenity.units}`;
};

// eslint-disable-next-line no-unused-vars
export const getRealEstateStatusName = (statusCode, locale = "ca-ES") => {
  if (!statusCode) return null;
  switch (statusCode) {
    case "FOR_SALE":
      return "En Venda";
    case "SOLD":
      return "Venut";
    case "SOLD_BY_OTHERS":
      return "Venut per 3rs";
    case "FOR_RENT":
      return "En Lloguer";
    case "FOR_RENT_SALE":
      return "En Lloguer amb opció a Compra";
    case "RENTED":
      return "Llogat";
    case "RENTED_BY_OTHERS":
      return "Llogat per 3rs";
    case "DISABLED":
      return "Inactiu";
    case "DRAFT":
      return "Esborrany";
    default:
      return statusCode;
  }
};

export const formatDateYmd = (date) => {
  const d = new Date(date);
  const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
  const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
  const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
  return `${ye}-${mo}-${da}`;
};

export const dateDiff = (date1, date2) => calculateDateDiff(date1, date2);

export const daysCount = (date1, date2) => calculateDateDiff(date1, date2) + 1;

// eslint-disable-next-line no-unused-vars
export const getOtaName = (otaCode) => {
  if (!otaCode) {
    return null;
  }

  switch (otaCode.toUpperCase()) {
    case "101HOTELS":
      return "101Hotels.com";
    case "AGODA":
      return "Agoda";
    case "AIRBNB":
      return "Airbnb";
    case "ATRAVEO":
      return "Atraveo";
    case "BOOKING":
      return "Booking.com";
    case "BUNGALOW":
      return "Bungalow";
    case "CASAMUNDO":
      return "Casamundo";
    case "EDOMIZIL":
      return "E-Domizil";
    case "EDREAMS":
      return "eDreams";
    case "EXPEDIA":
      return "Expedia";
    case "FORAVILA":
      return "Foravila Rentals";
    case "HOLIDU":
      return "Holidu";
    case "HOMEAWAY":
      return "HomeAway";
    case "HOMETOGO":
      return "HomeToGo";
    case "HOTELBEDS":
      return "Hotelbeds";
    case "HOUSETRIP":
      return "HouseTrip";
    case "LOCASUN":
      return "Locasun";
    case "MUCHOSOL":
      return "Muchosol";
    case "TRAUM":
      return "Traum-Ferienwohnungen";
    case "TRIPADVISOR":
      return "TripAdvisor";
    case "VACASOL":
      return "Vacasol";
    case "VILLAPARTNER":
      return "Villapartner";
    case "VRBO":
      return "Vrbo";
    case "WIMDU":
      return "Wimdu";
    default:
      return otaCode;
  }
};

// eslint-disable-next-line no-unused-vars
export const getDatesLockName = (scopeCode, locale = "ca-ES") => {
  if (!scopeCode) return null;
  switch (scopeCode.toUpperCase()) {
    case "BOOKING":
      return "Reserva externa";
    case "CLOSED":
      return "Allotjament no disponible";
    case "PERSONAL":
      return "Bloqueig per ús personal";
    default:
      return "Bloqueig";
  }
};

// Revisat i OK
export const getPaymentFrequencyName = (code, locale = "en-GB") => {
  if (!code) {
    return null;
  }

  switch (code.toUpperCase()) {
    case "HOUR":
      return i18n.t("Per hora", locale);
    case "DAY":
      return i18n.t("Per dia", locale);
    case "WEEK":
      return i18n.t("Per setmana", locale);
    case "LITER":
      return i18n.t("Per litre", locale);
    case "KW":
      return i18n.t("Per KW/h", locale);
    case "ONCE":
      return i18n.t("Pagament únic", locale);
    default:
      return code.toUpperCase();
  }
};

// Revisat i OK
export const getPaymentFrequencyUnit = (code, locale = "en-GB") => {
  if (!code) {
    return null;
  }

  switch (code.toUpperCase()) {
    case "HOUR":
      return i18n.t("/hora", locale);
    case "DAY":
      return i18n.t("/dia", locale);
    case "WEEK":
      return i18n.t("/setmana", locale);
    case "LITER":
      return i18n.t("/litre", locale);
    case "KW":
      return i18n.t("/KWh", locale);
    case "ONCE":
      return null;
    default:
      return code.toUpperCase();
  }
};

// eslint-disable-next-line no-unused-vars
export const getPaymentTimeName = (code, locale = "ca-ES") => {
  if (!code) return null;
  switch (code.toUpperCase()) {
    case "INITIAL_PAYMENT":
      return "Pagament inicial";
    case "FINAL_PAYMENT":
      return "Pagament final";
    case "BEFORE_CHECK_IN":
      return "Abans de l'entrada";
    case "CHECK_IN":
      return "A l'entrada";
    case "CHECK_OUT":
      return "A la sortida";
    case "PROVIDER":
      return "Al proveïdor";
    default:
      return code.toUpperCase();
  }
};

// eslint-disable-next-line no-unused-vars
export const getPaymentMethodName = (code, locale = "ca-ES") => {
  if (!code) return null;
  switch (code.toUpperCase()) {
    case "CREDIT_CARD":
      return "Targeta";
    case "VIRTUAL_CREDIT_CARD":
      return "Targeta virtual";
    case "SOURCE_PAYMENT":
      return "Pagament per portal";
    case "BANK_TRANSFER":
      return "Transferència";
    case "SECURITY_DEPOSIT":
      return "Retenció de fiança";
    case "CASH":
      return "Efectiu";
    case "PAYPAL":
      return "PayPal";
    case "BIZUM":
      return "Bizum";
    case "SOFORT":
      return "SOFORT";
    case "OTHER":
      return "Altre";
    default:
      return code.toUpperCase();
  }
};

export const getInvoiceStatusName = (code, locale = "ca-ES") => {
  if (!code) return null;
  switch (locale.toLowerCase()) {
    case "ca-es":
      switch (code.toUpperCase()) {
        case "PAID":
          return "Pagada";
        case "CHARGED":
          return "Cobrada";
        case "CANCELED":
        case "CANCELLED":
          return "Cancel·lada";
        case "ACCOUNTED":
          return "Comptabilitzada";
        case "PENDING":
          return "Pendent";
        default:
          return code.toUpperCase();
      }
    case "es-es":
      switch (code.toUpperCase()) {
        case "PAID":
          return "Pagada";
        case "CHARGED":
          return "Cobrada";
        case "CANCELED":
        case "CANCELLED":
          return "Cancelada";
        case "ACCOUNTED":
          return "Contabilizada";
        case "PENDING":
          return "Pendiente";
        default:
          return code.toUpperCase();
      }
    case "en-gb":
    default:
      switch (code.toUpperCase()) {
        case "PAID":
          return "Pagada";
        case "CHARGED":
          return "Cobrada";
        case "CANCELED":
        case "CANCELLED":
          return "Cancelled";
        case "ACCOUNTED":
          return "Accounted";
        case "PENDING":
          return "Pending";
        default:
          return code.toUpperCase();
      }
  }
};

export const getInvoiceStatusColor = (code) => {
  if (!code) {
    return null;
  }

  switch (code.toUpperCase()) {
    case "PAID":
    case "CHARGED":
      return "light-primary";
    case "PENDING":
      return "light-warning";
    case "CANCELED":
    case "CANCELLED":
      return "light-danger";
    case "ACCOUNTED":
      return "light-success";
    default:
      return "dark";
  }
};

export const getBookingStatusName = (code, locale = "en-GB") => {
  if (!code) {
    return null;
  }

  switch (code.toUpperCase()) {
    case "REQUESTED":
      return i18n.t("Sol·licitada", locale);
    case "CONFIRMED":
      return i18n.t("Confirmada", locale);
    case "CANCELED":
    case "CANCELLED":
      return i18n.t("Cancel·lada", locale);
    case "COMPLETED":
      return i18n.t("Completada", locale);
    default:
      return code.toUpperCase();
  }
};

export const getBookingStatusColor = (code) => {
  if (!code) {
    return null;
  }

  switch (code.toUpperCase()) {
    case "REQUESTED":
      return "warning";
    case "CONFIRMED":
      return "success";
    case "CANCELED":
    case "CANCELLED":
      return "danger";
    case "COMPLETED":
      return "primary";
    default:
      return "dark";
  }
};

export const getBookingSourceImage = (source) => {
  if (!source) {
    return null;
  }

  switch (source.toUpperCase()) {
    case "AIRBNB":
      return "https://media.foravilarentals.com/logos/otas/logo-airbnb-100x100.png";
    case "BOOKING":
      return "https://media.foravilarentals.com/logos/otas/logo-booking-100x100.png";
    case "FORAVILA":
      return "https://media.foravilarentals.com/logos/otas/logo-foravila-100x100.png";
    case "VRBO":
    case "HOMEAWAY":
      return "https://media.foravilarentals.com/logos/otas/logo-vrbo-100x100.png";
    case "TRIPADVISOR":
      return "https://media.foravilarentals.com/logos/otas/logo-tripadvisor-100x100.png";
    case "ATRAVEO":
      return "https://media.foravilarentals.com/logos/otas/logo-atraveo-100x100.png";
    case "LOCASUN":
      return "https://media.foravilarentals.com/logos/otas/logo-locasun-100x100.png";
    case "HOLIDU":
      return "https://media.foravilarentals.com/logos/otas/logo-holidu-100x100.png";
    case "HOMETOGO":
      return "https://media.foravilarentals.com/logos/otas/logo-hometogo-100x100.png";
    default:
      return "https://media.foravilarentals.com/logos/otas/logo-altres-100x100.png";
  }
};

// eslint-disable-next-line no-unused-vars
export const getContractStatusName = (code, locale = "ca-ES") => {
  if (!code) return null;
  switch (code.toUpperCase()) {
    case "DRAFT":
      return "Esborrany";
    case "PENDING_SIGNATURE":
      return "Pendent de signatura";
    case "ACTIVE":
      return "Actiu";
    case "PENDING_RENEWAL":
      return "Pendent de renovació";
    case "TERMINATED":
      return "Finalitzat";
    default:
      return code.toUpperCase();
  }
};

// eslint-disable-next-line no-unused-vars
export const getAcceptedBookingTypeName = (code, locale = "ca-ES") => {
  if (!code) return null;
  switch (code.toUpperCase()) {
    case "REQUEST":
      return "Petició";
    case "DIRECT":
      return "Directa";
    default:
      return code.toUpperCase();
  }
};

// eslint-disable-next-line no-unused-vars
export const getBillingTypeName = (code, locale = "ca-ES") => {
  if (!code) return null;
  switch (code.toUpperCase()) {
    case "COMISSION":
      return "Comissió";
    case "SUBURANCE":
      return "Subarrendament";
    default:
      return code.toUpperCase();
  }
};

export const getContractStatusColor = (code) => {
  if (!code) return null;
  switch (code.toUpperCase()) {
    case "ACTIVE":
      return "success";
    case "DRAFT":
      return "primary";
    case "PENDING_SIGNATURE":
    case "PENDING_RENEWAL":
      return "warning";
    case "TERMINATED":
      return "dark";
    default:
      return "info";
  }
};

export const getManagementTypeName = (code, locale = "ca-ES") => {
  if (!code) return null;
  switch (simplifyLocale(locale)) {
    case "ca":
      switch (code.toUpperCase()) {
        case "SHARED":
          return "Compartida";
        case "EXCLUSIVE":
          return "Exclusiva";
        default:
          return code.toUpperCase();
      }
    case "es":
      switch (code.toUpperCase()) {
        case "SHARED":
          return "Compartida";
        case "EXCLUSIVE":
          return "Exclusiva";
        default:
          return code.toUpperCase();
      }
    case "en":
    default:
      switch (code.toUpperCase()) {
        case "SHARED":
          return "Shared";
        case "EXCLUSIVE":
          return "Exclusive";
        default:
          return code.toUpperCase();
      }
  }
};

// eslint-disable-next-line no-unused-vars
export const getTeamMemberName = (member, locale = "ca-ES") => {
  if (!member) return null;
  const words = member.split(" ");
  return words
    .map((word) => word[0].toUpperCase() + word.toLowerCase().substring(1))
    .join(" ");
};

// Revisat i OK
export const getHoursText = (hours, locale = "ca-ES") => {
  if (!hours) {
    return null;
  }

  const days = hours / 24;
  let text;

  if (hours > 48) {
    text = days === 1 ? i18n.t("Dia", locale) : i18n.t("Dies", locale);
  } else {
    text = hours === 1 ? i18n.t("Hora", locale) : i18n.t("Hores", locale);
  }

  // Only if the locale is not German, return the text "lowercased",
  // if the locale is german the text should be capitalized.
  if (locale !== "de-DE") {
    text = text.toLowerCase();
  }

  if (hours > 48) {
    return `${days} ${text}`;
  }

  return `${hours} ${text}`;
};

// Revisat i OK
export const getNightsText = (nights, locale = "en-GB") => {
  if (!nights) {
    return null;
  }

  let text =
    nights === 1 ? i18n.t("Vespre", locale) : i18n.t("Vespres", locale);

  // Only if the locale is not German, return the text "lowercased",
  // if the locale is german the text should be capitalized.
  if (locale !== "de-DE") {
    text = text.toLowerCase();
  }

  return `${nights} ${text}`;
};

// Revisat i OK
export const getDaysText = (days, locale = "en-GB") => {
  if (!days) {
    return null;
  }

  let text = days === 1 ? i18n.t("Dia", locale) : i18n.t("Dies", locale);

  // Only if the locale is not German, return the text "lowercased",
  // if the locale is german the text should be capitalized.
  if (locale !== "de-DE") {
    text = text.toLowerCase();
  }

  return `${days} ${text}`;
};

// Revisat i OK
export const getWeekdayTextFromNumber = (
  dayNumber,
  locale = "en-GB",
  abbr = false
) => {
  if (![0, 1, 2, 3, 4, 5, 6].includes(parseInt(dayNumber))) {
    return null;
  }

  const daysMapping = {
    0: i18n.t("weekdays.sunday.full", locale),
    1: i18n.t("weekdays.monday.full", locale),
    2: i18n.t("weekdays.tuesday.full", locale),
    3: i18n.t("weekdays.wednesday.full", locale),
    4: i18n.t("weekdays.thursday.full", locale),
    5: i18n.t("weekdays.friday.full", locale),
    6: i18n.t("weekdays.saturday.full", locale),
  };

  const abbrDaysMapping = {
    0: i18n.t("weekdays.sunday.abbr", locale),
    1: i18n.t("weekdays.monday.abbr", locale),
    2: i18n.t("weekdays.tuesday.abbr", locale),
    3: i18n.t("weekdays.wednesday.abbr", locale),
    4: i18n.t("weekdays.thursday.abbr", locale),
    5: i18n.t("weekdays.friday.abbr", locale),
    6: i18n.t("weekdays.saturday.abbr", locale),
  };

  let text = abbr ? abbrDaysMapping[dayNumber] : daysMapping[dayNumber];

  // Only if the locale is not German, return the text "lowercased",
  // if the locale is german the text should be capitalized.
  if (!["de-DE", "en-GB"].includes(locale)) {
    text = text.toLowerCase();
  }

  return text;
};

// Revisat i OK
export const getWeekdaysText = (days, locale = "en-GB", abbr = false) => {
  if (!days?.length) {
    return null;
  }

  return days
    .map((dayNumber) => getWeekdayTextFromNumber(dayNumber, locale, abbr))
    .join(", ");
};

// Revisat i OK
export const getBookingsText = (bookings, locale = "en-GB") => {
  if (!bookings) {
    return null;
  }

  let text =
    bookings === 1 ? i18n.t("Reserva", locale) : i18n.t("Reserves", locale);

  // Only if the locale is not German, return the text "lowercased",
  // if the locale is german the text should be capitalized.
  if (locale !== "de-DE") {
    text = text.toLowerCase();
  }

  return `${bookings} ${text}`;
};

// Revisat i OK
export const getAdultsText = (adults, locale = "en-GB") => {
  if (!adults && adults !== 0) {
    return null;
  }

  let text = adults === 1 ? i18n.t("Adult", locale) : i18n.t("Adults", locale);

  // Only if the locale is not German, return the text "lowercased",
  // if the locale is german the text should be capitalized.
  if (locale !== "de-DE") {
    text = text.toLowerCase();
  }

  return `${adults} ${text}`;
};

// Revisat i OK
export const getChildrensText = (children, locale = "en-GB") => {
  if (!children && children !== 0) {
    return null;
  }

  let text =
    children === 1 ? i18n.t("Nin/a", locale) : i18n.t("Nins/es", locale);

  // Only if the locale is not German, return the text "lowercased",
  // if the locale is german the text should be capitalized.
  if (locale !== "de-DE") {
    text = text.toLowerCase();
  }

  return `${children} ${text}`;
};

// Revisat i OK
export const getBabiesText = (babies, locale = "en-GB") => {
  if (!babies && babies !== 0) {
    return null;
  }

  let text = babies === 1 ? i18n.t("Nadó", locale) : i18n.t("Nadons", locale);

  // Only if the locale is not German, return the text "lowercased",
  // if the locale is german the text should be capitalized.
  if (locale !== "de-DE") {
    text = text.toLowerCase();
  }

  return `${babies} ${text}`;
};

// Revisat i OK
export const getBabyChairsText = (babyChairs, locale = "en-GB") => {
  if (!babyChairs && babyChairs !== 0) {
    return null;
  }

  let text =
    babyChairs === 1 ? i18n.t("Trona", locale) : i18n.t("Trones", locale);

  // Only if the locale is not German, return the text "lowercased",
  // if the locale is german the text should be capitalized.
  if (locale !== "de-DE") {
    text = text.toLowerCase();
  }

  return `${babyChairs} ${text}`;
};

// Revisat i OK
export const getBabyCotsText = (babyCots, locale = "en-GB") => {
  if (!babyCots && babyCots !== 0) {
    return null;
  }

  let text = babyCots === 1 ? i18n.t("Cuna", locale) : i18n.t("Cunes", locale);

  // Only if the locale is not German, return the text "lowercased",
  // if the locale is german the text should be capitalized.
  if (locale !== "de-DE") {
    text = text.toLowerCase();
  }

  return `${babyCots} ${text}`;
};

// Revisat i OK
export const getGuestsText = (guests, locale = "en-GB") => {
  if (!guests || guests.length === 0) {
    return null;
  }

  const text = [];

  if (guests.adults > 0) {
    text.push(getAdultsText(guests.adults, locale));
  }

  if (guests.children > 0) {
    text.push(getChildrensText(guests.children, locale));
  }

  if (guests.babies > 0) {
    text.push(getBabiesText(guests.babies, locale));
  }

  return text.join(", ");
};

// Revisat i OK
export const getTransportName = (code, locale = "en-GB") => {
  if (!code) {
    return null;
  }

  switch (code.toUpperCase()) {
    case "PLANE":
      return i18n.t("Avió", locale);
    case "BOAT":
      return i18n.t("Ferry", locale);
    case "NONE":
      return i18n.t("Sense transport", locale);
    default:
      return code.toUpperCase();
  }
};

// Revisat i OK
export const getServiceTypeName = (code, locale = "en-GB") => {
  if (!code) {
    return null;
  }

  switch (code.toUpperCase()) {
    case "REQUIREMENT":
      return i18n.t("Requeriment", locale);
    case "ACCOMMODATION_EXTRA":
      return i18n.t("Extra allotjament", locale);
    case "3RD_PARTY_SERVICE":
      return i18n.t("Servei de tercers", locale);
    default:
      return code.toUpperCase();
  }
};

// Revisat i OK
export const getGenderName = (code, locale = "en-GB") => {
  if (!code) {
    return null;
  }

  switch (code.toUpperCase()) {
    case "MALE":
      return i18n.t("Home", locale);
    case "FEMALE":
      return i18n.t("Dona", locale);
    case "OTHER":
      return i18n.t("Altre", locale);
    default:
      return code.toUpperCase();
  }
};

// Revisat i OK
export const getIdTypeName = (code, locale = "en-GB") => {
  if (!code) {
    return null;
  }

  switch (code.toUpperCase()) {
    case "PASSPORT":
      return i18n.t("Passaport", locale);
    case "DNI":
      return i18n.t("DNI", locale);
    case "CIF":
      return i18n.t("CIF", locale);
    default:
      return code.toUpperCase();
  }
};

// Revisat i OK
export const getClientPaymentStatusName = (code, locale = "en-GB") => {
  if (!code) return null;
  switch (code.toUpperCase()) {
    case "PRE_CONFIRMED":
      return i18n.t("Pre-confirmat", locale);
    case "CONFIRMED":
      return i18n.t("Confirmat", locale);
    case "VERIFIED":
      return i18n.t("Verificat", locale);
    case "ACCOUNTED":
      return i18n.t("Comptabilitzat", locale);
    case "PARTIALLY_ACCOUNTED":
      return i18n.t("Parcialment comptabilitzat", locale);
    case "OTHER":
      return i18n.t("Altre", locale);
    default:
      return code.toUpperCase();
  }
};

// eslint-disable-next-line no-unused-vars
export const getBookingCommentAuthor = (code, locale = "ca-ES") => {
  if (!code) return null;
  switch (code.toUpperCase()) {
    case "OWNER":
      return "Propietari";
    case "CLIENT":
      return "Client";
    case "AGENCY":
      return "Foravila Rentals";
    case "SOURCE":
      return "Portal";
    case "CLEANING_PROVIDER":
      return "Neteja";
    case "GARDEN_PROVIDER":
      return "Jardí";
    case "POOL_PROVIDER":
      return "Piscina";
    case "HOUSEHOLD_CLOTHES_PROVIDER":
      return "Bugaderia";
    default:
      return code.toUpperCase();
  }
};

// eslint-disable-next-line no-unused-vars
export const getClientPaymentScopeName = (code, locale = "ca-ES") => {
  if (!code) return null;
  switch (code.toUpperCase()) {
    case "BOOKING":
      return "Reserva";
    case "SECURITY_DEPOSIT":
      return "Fiança";
    case "TOURIST_TAX":
      return "Impost turístic";
    case "SERVICE":
      return "Servei";
    case "OTHER":
      return "Altre";
    default:
      return code.toUpperCase();
  }
};

// Revisat i OK
export const getServiceName = (service, locale = "en-GB") => {
  if (!service) {
    return null;
  }

  // First try to get the service name from the service descriptions
  const serviceDescription = service.descriptions || [];
  const nameFromDescription = getServiceNameFromServiceDescriptions(
    serviceDescription,
    locale
  );

  if (nameFromDescription) {
    return nameFromDescription;
  }

  // If is not possible, infer the name from the code
  if (!service.code) {
    return null;
  }

  return getServiceNameFromCode(service.code, locale);
};

// Revisat i OK
export const getServiceNameFromServiceDescriptions = (
  descriptions,
  locale = "en-GB"
) => {
  if (!descriptions.length) {
    return null;
  }

  const localizedDescription = descriptions.find(
    (description) => description.language === simplifyLocale(locale)
  );

  if (!localizedDescription) {
    const englishDescription = descriptions.find(
      (description) => description.language === "en"
    );
    if (!englishDescription) {
      return descriptions[0]?.name || null;
    }
    return englishDescription.name || descriptions[0]?.name || null;
  }

  return localizedDescription.name || null;
};

// Revisat i OK
export const getServiceNameFromCode = (code, locale = "en-GB") => {
  if (!code) {
    return null;
  }

  switch (code.toUpperCase()) {
    case "AIR_CONDITIONING":
      return i18n.t("Aire Acondicionat", locale);
    case "TOURIST_TAX":
      return i18n.t("Impost turístic", locale);
    case "SECURITY_DEPOSIT":
      return i18n.t("Fiança", locale);
    case "CLEANING":
      return i18n.t("Neteja", locale);
    case "ADDITIONAL_CLEANING":
      return i18n.t("Neteja addicional", locale);
    case "FINAL_CLEANING":
      return i18n.t("Neteja final", locale);
    case "AIRPORT_TRANSFER":
      return i18n.t("Transfer aeroport", locale);
    case "TRANSFER":
      return i18n.t("Transfer", locale);
    case "CAR_RENTAL":
      return i18n.t("Lloguer de cotxe", locale);
    case "ELECTRICITY_CONSUMPTION":
      return i18n.t("Consum elèctric", locale);
    case "HEATING_CONSUMPTION":
      return i18n.t("Consum calefacció", locale);
    case "CLIMA_CONSUMPTION":
      return i18n.t("Consum climatització", locale);
    case "FIREWOOD":
      return i18n.t("Llenya", locale);
    case "BABY_COT":
      return i18n.t("Cuna", locale);
    case "BABY_CHAIR":
      return i18n.t("Trona", locale);
    case "JACUZZI":
      return i18n.t("Jacuzzi", locale);
    case "HEATED_POOL":
    case "HEATED_SWIMMING_POOL":
    case "POOL_HEATING":
      return i18n.t("Piscina climatitzada", locale);
    case "SUPERMARKET":
      return i18n.t("Supermercat", locale);
    case "CHEF_AT_HOME":
      return i18n.t("Chef a domicili", locale);
    case "EXTRA_SINGLE_BED":
      return i18n.t("Llit individual extra", locale);
    case "DAMAGES":
      return i18n.t("Rotures i desperfectes", locale);
    case "OTHER":
      return i18n.t("Altre", locale);
    default:
      return code.toUpperCase();
  }
};

// Revisat i OK
export const getRuleNameFromRuleDescriptions = (
  descriptions,
  locale = "en-GB"
) => {
  if (!descriptions.length) {
    return null;
  }

  const localizedDescription = descriptions.find(
    (description) => description.language === simplifyLocale(locale)
  );

  if (!localizedDescription) {
    const englishDescription = descriptions.find(
      (description) => description.language === "en"
    );
    if (!englishDescription) {
      return descriptions[0]?.name || null;
    }
    return englishDescription.name || descriptions[0]?.name || null;
  }

  return localizedDescription.name || null;
};

export const calculateNights = (checkin, checkout) => {
  if (!checkin || !checkout) return null;
  const dt1 = new Date(checkin);
  const dt2 = new Date(checkout);
  return Math.floor(
    (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
      Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
      (1000 * 60 * 60 * 24)
  );
};

export const sameDates = (date1, date2) => {
  // Check if date1 and date2 are valid Date objects
  if (
    !(date1 instanceof Date) ||
    isNaN(date1) ||
    !(date2 instanceof Date) ||
    isNaN(date2)
  ) {
    return false;
  }

  // Get the local year, month, and day for each date
  const localDate1 =
    date1.getFullYear() +
    "-" +
    (date1.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    date1.getDate().toString().padStart(2, "0");
  const localDate2 =
    date2.getFullYear() +
    "-" +
    (date2.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    date2.getDate().toString().padStart(2, "0");

  // Compare the local date parts
  return localDate1 === localDate2;
};

export const calculateTouristTaxPrice = (
  checkin,
  checkout,
  guests,
  applyTax = false
) => {
  if (!checkin || !checkout || !guests) return null;

  try {
    const nights = calculateNights(checkin, checkout);
    if (nights <= 0) return null;

    // 0 indexed months
    const highSeasonMonths = [4, 5, 6, 7, 8, 9];

    // Prices in € (e.j. 200 = 2€)
    const highSeasonPrice = 200;
    const highSeasonExtraPrice = 100;
    const lowSeasonPrice = 50;
    const lowSeasonExtraPrice = 25;

    // Make sure checkin param is a date
    const checkinDate = new Date(checkin);

    // Calculate the price for one guest
    let totalPricePerPerson = 0;
    for (let i = 1; i <= nights; i += 1) {
      if (highSeasonMonths.indexOf(checkinDate.getMonth()) !== -1) {
        // check-in en temporada alta, estada de 8 dies o menys
        if (i <= 8) totalPricePerPerson += highSeasonPrice;
        // check-in en temporada alta, estada de més de 8 dies
        else totalPricePerPerson += highSeasonExtraPrice;
      } else if (i <= 8) {
        // check-in en temporada baixa, estada de 8 dies o menys
        totalPricePerPerson += lowSeasonPrice;
      } else {
        // check-in en temporada baixa, estada de més de 8 dies
        totalPricePerPerson += lowSeasonExtraPrice;
      }
    }

    const totalBase = totalPricePerPerson * guests;
    const totalTax = totalBase * 0.1;
    if (applyTax) return totalBase + totalTax;
    return totalBase;
  } catch (error) {
    return null;
  }
};

// eslint-disable-next-line no-unused-vars
export const getBookingLogTypeName = (bookingLogTypeCode, locale = "ca-ES") => {
  if (!bookingLogTypeCode) return null;
  switch (bookingLogTypeCode.toUpperCase()) {
    case "BOOKING_UPDATED":
      return "Reserva actualitzada";
    case "CLIENT_UPDATED":
      return "Dades del client actualitzades";
    case "CLIENT_PREBOOKING_CONFIRMATION_SENT":
      return "Confirmació de pre-reserva enviada al client";
    case "CLIENT_BOOKING_CONFIRMATION_SENT":
      return "Confirmació de reserva enviada al client";
    case "CLIENT_BOOKING_MODIFICATION_SENT":
      return "Modificació de reserva enviada al client";
    case "CLIENT_BOOKING_CANCELLATION_SENT":
      return "Cancel·lació de reserva enviada al client";
    case "CLIENT_CHECKIN_INSTRUCTIONS_SENT":
      return "Instruccions d'arribada enviades al client";
    case "CLIENT_REVIEW_REQUEST_SENT":
      return "Sol·licitut de ressenya enviada al client";
    case "CLIENT_INITIAL_PAYMENT_REQUEST_SENT":
      return "Sol·licitut de pagament inicial enviada al client";
    case "CLIENT_FINAL_PAYMENT_REQUEST_SENT":
      return "Sol·licitut de pagament final enviada al client";
    case "CLIENT_LAST_FINAL_PAYMENT_REQUEST_SENT":
      return "Amenaça de pagament final enviada al client";
    case "CLIENT_SERVICES_PAYMENT_REQUEST_SENT":
      return "Sol·licitut de pagament de serveis enviada al client";
    case "CLIENT_ONLINE_CHECKIN_REQUEST_SENT":
      return "Sol·licitut de check-in online enviada al client";
    case "CLIENT_INFORMATION_REQUEST_SENT":
      return "Sol·licitut d'informació d'arribada enviada al client";
    case "CLIENT_PRIVACY_POLICY_ACCEPTED":
      return "Política de privacitat acceptada pel client";
    case "OWNER_BOOKING_NOTIFICATION_SENT":
      return "Notificació de reserva enviada al propietari";
    case "OWNER_EMAIL_BOOKING_NOTIFICATION_SENT":
      return "Notificació de reserva enviada al propietari per Email";
    case "OWNER_WHATSAPP_BOOKING_NOTIFICATION_SENT":
      return "Notificació de reserva enviada al propietari per WhatsApp";
    case "OWNER_EMAIL_CHECKIN_REMINDER_SENT":
      return "Recordatori d'arribada enviat al propietari per Email";
    case "OWNER_WHATSAPP_CHECKIN_REMINDER_SENT":
      return "Recordatori d'arribada enviat al propietari per WhatsApp";
    case "OWNER_BOOKING_CONFIRMATION_SENT":
      return "Confirmació de reserva enviada al propietari ";
    case "OWNER_BOOKING_MODIFICATION_SENT":
      return "Modificació de reserva enviada al propietari ";
    case "OWNER_BOOKING_CANCELLATION_SENT":
      return "Cancel·lació de reserva enviada al propietari ";
    case "OWNER_CHECKIN_INFORMATION_SENT":
      return "Informació d'arribada enviada al propietari";
    case "OWNER_GUESTS_REPORT_SENT":
      return "Parte de viatgers enviat al propietari";
    case "POLICE_REPORT_SENT":
      return "Parte de viatgers enviat a la Policia";
    default:
      return bookingLogTypeCode;
  }
};

// Returns a variant color name that represents the given booking log code
export const getBookingLogVariant = (bookingLogTypeCode) => {
  if (!bookingLogTypeCode) {
    return "info";
  }

  switch (bookingLogTypeCode.toUpperCase()) {
    case "CLIENT_PREBOOKING_CONFIRMATION_SENT":
    case "CLIENT_BOOKING_CONFIRMATION_SENT":
    case "OWNER_BOOKING_CONFIRMATION_SENT":
      return "primary";
    case "CLIENT_PRIVACY_POLICY_ACCEPTED":
    case "OWNER_GUESTS_REPORT_SENT":
    case "POLICE_REPORT_SENT":
      return "success";
    case "CLIENT_REVIEW_REQUEST_SENT":
    case "CLIENT_INITIAL_PAYMENT_REQUEST_SENT":
    case "CLIENT_FINAL_PAYMENT_REQUEST_SENT":
    case "CLIENT_LAST_FINAL_PAYMENT_REQUEST_SENT":
    case "CLIENT_SERVICES_PAYMENT_REQUEST_SENT":
    case "CLIENT_ONLINE_CHECKIN_REQUEST_SENT":
    case "CLIENT_INFORMATION_REQUEST_SENT":
      return "warning";
    case "CLIENT_BOOKING_CANCELLATION_SENT":
    case "OWNER_BOOKING_CANCELLATION_SENT":
      return "danger";
    case "BOOKING_UPDATED":
    case "CLIENT_CHECKIN_INSTRUCTIONS_SENT":
    case "OWNER_CHECKIN_INFORMATION_SENT":
    case "CLIENT_BOOKING_MODIFICATION_SENT":
    case "OWNER_BOOKING_MODIFICATION_SENT":
    default:
      return "info";
  }
};

// Returns a feather icon name that represents the given booking log code
export const getBookingLogIcon = (bookingLogTypeCode) => {
  if (!bookingLogTypeCode) {
    return "InfoIcon";
  }

  switch (bookingLogTypeCode.toUpperCase()) {
    case "CLIENT_BOOKING_CONFIRMATION":
      return "UserCheckIcon";
    case "OWNER_BOOKING_CONFIRMATION":
      return "CheckIcon";
    case "CLIENT_CHECKIN_INSTRUCTIONS_SENT":
      return "LogInIcon";
    case "OWNER_CHECKIN_INFORMATION_SENT":
      return "InfoIcon";
    case "CLIENT_INFORMATION_REQUESTED":
      return "FileTextIcon";
    case "CLIENT_REVIEW_REQUESTED":
      return "MessageSquareIcon";
    case "CLIENT_PAYMENT_REQUESTED":
      return "DollarSignIcon";
    case "POLICE_REPORT_SENT":
      return "ShieldIcon";
    case "PRIVACY_POLICY_ACCEPTED":
      return "PocketIcon";
    case "BOOKING_UPDATED":
      return "RefreshCwIcon";
    default:
      return "InfoIcon";
  }
};

// Returns the total amount corresponding to the owner of a given booking service
export const getBookingServiceOwnerTotalPrice = (bookingService, nights) => {
  if (!bookingService) {
    return null;
  }

  // If the payment frequency depends on consumption, the total price
  // is uncalculabe, return null
  if (
    bookingService.paymentFrequency &&
    uncalculableFrequencies.includes(bookingService.paymentFrequency)
  ) {
    return null;
  }

  // If the cost price is not defined, get the pvp price by default
  const basePrice = calculateBookingServiceOwnerBasePrice(bookingService);

  let serviceTotalPrice = basePrice;

  if (bookingService.paymentFrequency === "DAY" && nights) {
    serviceTotalPrice = basePrice * nights;
  }

  return serviceTotalPrice;
};

// Returns the total amount corresponding to the owner of a given booking service
// or the unit price in case the total can not be calculated
export const getBookingServiceOwnerTotalOrUnitPrice = (
  bookingService,
  nights
) => {
  if (!bookingService) return null;

  const bookingServiceTotalPrice = getBookingServiceOwnerTotalPrice(
    bookingService,
    nights
  );

  if (!bookingServiceTotalPrice) {
    return calculateBookingServiceOwnerBasePrice(bookingService);
  }

  return bookingServiceTotalPrice;
};

// Returns the text version of the unit price corresponding to the owner of a given booking service
export const getBookingServiceOwnerUnitPriceText = (bookingService, locale) => {
  if (!bookingService) {
    return null;
  }

  if (
    (!bookingService.pvpPrice && !bookingService.costPrice) ||
    !bookingService.paymentFrequency
  ) {
    return null;
  }

  // If the cost price is not defined, get the pvp price by default
  const basePrice = calculateBookingServiceOwnerBasePrice(bookingService);

  const bookingServiceUnitPrice = formatCurrency(basePrice);
  const paymentFrequencyUnit =
    getPaymentFrequencyUnit(bookingService.paymentFrequency, locale) || "";

  return `${bookingServiceUnitPrice}${paymentFrequencyUnit}`;
};

// Returns the text version of the total price corresponding to the owner of a given booking service
export const getBookingServiceOwnerTotalPriceText = (
  bookingService,
  nights,
  locale
) => {
  if (!bookingService) return null;

  const bookingServicePrice = getBookingServiceOwnerTotalPrice(
    bookingService,
    nights
  );

  // If the booking services has no payment frequency or it has a daily payment frequency (it can be calculated in advance),
  // return the calculated total.
  if (
    !bookingService?.paymentFrequency ||
    bookingService.paymentFrequency === "DAY"
  ) {
    return formatCurrency(bookingServicePrice);
  }

  // If not, return the unit pvp price and the unit text
  return getBookingServiceOwnerUnitPriceText(bookingService, locale);
};

// Returns the total amount corresponding to the client of a given booking service
export const getBookingServiceClientTotalPrice = (bookingService, nights) => {
  if (!bookingService) return null;

  // If the payment frequency depends on consumption, the total price
  // is uncalculabe, return null
  if (
    bookingService.paymentFrequency &&
    uncalculableFrequencies.includes(bookingService.paymentFrequency)
  ) {
    return null;
  }

  let serviceTotalPrice = bookingService.pvpPrice;

  // If the payment frequency is daily, calculate the total price keeping
  // in mind the duration of the reservation
  if (bookingService.paymentFrequency === "DAY" && nights) {
    serviceTotalPrice = bookingService.pvpPrice * nights;
  }

  return serviceTotalPrice;
};

// Returns the total amount corresponding to the client of a given booking service
// or the unit price in case it can not be calculated
export const getBookingServiceClientTotalOrUnitPrice = (
  bookingService,
  nights
) => {
  if (!bookingService) return null;

  const bookingServiceTotalPrice = getBookingServiceClientTotalPrice(
    bookingService,
    nights
  );

  if (!bookingServiceTotalPrice) return bookingService.pvpPrice;
  return bookingServiceTotalPrice;
};

// Returns the total paid amount of a given booking service
export const getBookingServicePaidPrice = (bookingService) => {
  if (!bookingService) return null;

  let servicePaidPrice = 0;
  if (bookingService.clientPayments.length) {
    servicePaidPrice = bookingService.clientPayments.reduce((acc, payment) => {
      if (
        ["ACCOUNTED", "VERIFIED", "CONFIRMED", "PRE_CONFIRMED"].includes(
          payment.status
        )
      )
        return acc + (payment.pvpAmount || 0);
      return acc;
    }, 0);
  }

  return servicePaidPrice;
};

// Returns the price pending to be paid of a given booking service
export const getBookingServicePendingPrice = (bookingService, nights) => {
  if (!bookingService) return null;

  const serviceTotalPrice = getBookingServiceClientTotalPrice(
    bookingService,
    nights
  );
  const servicePaidPrice = getBookingServicePaidPrice(bookingService);

  return serviceTotalPrice - servicePaidPrice;
};

// Returns the text version of the unit price corresponding to the client of a given booking service
export const getBookingServiceClientUnitPriceText = (
  bookingService,
  locale
) => {
  if (!bookingService) return null;
  if (!bookingService.pvpPrice && bookingService.pvpPrice !== 0) return null;
  if (!bookingService.paymentFrequency) return null;

  const bookingServiceUnitPrice = formatCurrency(bookingService.pvpPrice);
  const paymentFrequencyUnit =
    getPaymentFrequencyUnit(bookingService.paymentFrequency, locale) || "";

  return `${bookingServiceUnitPrice}${paymentFrequencyUnit}`;
};

// Returns the text version of the total price corresponding to the client of a given booking service
export const getBookingServiceClientTotalPriceText = (
  bookingService,
  nights,
  locale
) => {
  if (!bookingService) return null;

  const bookingServicePrice = getBookingServiceClientTotalPrice(
    bookingService,
    nights
  );

  // If the booking services has no payment frequency or it has a daily payment frequency (it can be calculated in advance),
  // return the calculated total.
  if (
    !bookingService?.paymentFrequency ||
    bookingService.paymentFrequency === "DAY"
  )
    return formatCurrency(bookingServicePrice);

  // If not, return the unit pvp price and the unit text
  return getBookingServiceClientUnitPriceText(bookingService, locale);
};

// Revisat i OK
// Returns the name of a given booking service based on the service code
export const getBookingServiceName = (bookingService, locale = "ca-ES") => {
  if (!bookingService?.service) {
    return null;
  }

  return getServiceName(bookingService.service, locale);
};

// Returns the name of a given booking service based on the service code, and
// the text version of the unit price corresponding to the client
export const getBookingServiceNameWithClientUnitPrice = (
  bookingService,
  locale = "ca-ES"
) => {
  if (!bookingService) return null;

  const name = [];
  if (bookingService?.service?.code)
    name.push(getServiceName(bookingService.service, locale));
  const unitPriceText = getBookingServiceClientUnitPriceText(
    bookingService,
    locale
  );
  if (unitPriceText) name.push(`(${unitPriceText})`);

  return name.join(" ");
};

// Returns the name of a given booking service based on the service code, and
// the text version of the unit price corresponding to the owner
export const getBookingServiceNameWithOwnerUnitPrice = (
  bookingService,
  locale = "ca-ES"
) => {
  if (!bookingService) return null;

  const name = [];
  if (bookingService?.service?.code)
    name.push(getServiceName(bookingService.service, locale));
  const unitPriceText = getBookingServiceOwnerUnitPriceText(
    bookingService,
    locale
  );
  if (unitPriceText) name.push(`(${unitPriceText})`);

  return name.join(" ");
};

export const getBookingServicePvpInvoicedPrice = (bookingService) => {
  if (!bookingService?.invoiceLines?.length) {
    return 0;
  }

  let invoicedPrice = 0;

  bookingService.invoiceLines.forEach((invoiceLine) => {
    if (invoiceLine.type === "SERVICE_PVP") {
      invoicedPrice += invoiceLine.pvpPrice || 0;
    }
  });

  return invoicedPrice;
};

export const getBookingServicePvpIsInvoiced = (bookingService) => {
  return getBookingServicePvpInvoicedPrice(bookingService) > 0;
};

export const getBookingServicePvpIsFullyInvoiced = (bookingService) => {
  if (bookingService?.pvpPrice == null) return null;
  return (
    getBookingServicePvpInvoicedPrice(bookingService) ===
    bookingService.pvpPrice
  );
};

export const getBookingServicePvpIsUnderInvoiced = (bookingService) => {
  if (bookingService?.pvpPrice == null) return null;
  return (
    getBookingServicePvpInvoicedPrice(bookingService) < bookingService.pvpPrice
  );
};

export const getBookingServicePvpIsOverInvoiced = (bookingService) => {
  if (bookingService?.pvpPrice == null) return null;
  return (
    getBookingServicePvpInvoicedPrice(bookingService) > bookingService.pvpPrice
  );
};

export const getBookingServicePvpInvoicePendingPrice = (bookingService) => {
  if (bookingService?.pvpPrice == null) return null;
  return (
    bookingService.pvpPrice - getBookingServicePvpInvoicedPrice(bookingService)
  );
};

export const getBookingServiceComissionPrice = (bookingService) => {
  const { costPrice, pvpPrice, vatPercentage, irpfPercentage } =
    bookingService || {};

  if (pvpPrice == null || costPrice == null) {
    return null;
  }

  const ownerPriceVatAmount =
    calculatePercentagePriceFromBasePrice(costPrice, vatPercentage || 0) || 0;
  const ownerPriceIrpfAmount =
    calculatePercentagePriceFromBasePrice(costPrice, irpfPercentage || 0) || 0;
  const ownerPriceWithVat =
    calculatePvpPrice(costPrice, ownerPriceVatAmount, ownerPriceIrpfAmount) ||
    0;

  return pvpPrice - ownerPriceWithVat;
};

export const getBookingServiceComissionInvoicedPrice = (bookingService) => {
  if (!bookingService?.invoiceLines?.length) {
    return 0;
  }

  let invoicedPrice = 0;

  bookingService.invoiceLines.forEach((invoiceLine) => {
    if (invoiceLine.type === "SERVICE_COMISSION") {
      invoicedPrice += invoiceLine.pvpPrice || 0;
    }
  });

  return invoicedPrice;
};

export const getBookingServiceComissionIsInvoiced = (bookingService) => {
  return getBookingServiceComissionInvoicedPrice(bookingService) > 0;
};

export const getBookingServiceComissionIsFullyInvoiced = (bookingService) => {
  const comissionPrice = getBookingServiceComissionPrice(bookingService);

  if (comissionPrice == null) return null;

  return (
    getBookingServiceComissionInvoicedPrice(bookingService) === comissionPrice
  );
};

export const getBookingServiceComissionIsUnderInvoiced = (bookingService) => {
  const comissionPrice = getBookingServiceComissionPrice(bookingService);

  if (comissionPrice == null) return null;

  return (
    getBookingServiceComissionInvoicedPrice(bookingService) < comissionPrice
  );
};

export const getBookingServiceComissionIsOverInvoiced = (bookingService) => {
  const comissionPrice = getBookingServiceComissionPrice(bookingService);

  if (comissionPrice == null) return null;
  return (
    getBookingServiceComissionInvoicedPrice(bookingService) > comissionPrice
  );
};

export const getBookingServiceComissionInvoicePendingPrice = (
  bookingService
) => {
  const comissionPrice = getBookingServiceComissionPrice(bookingService);

  if (comissionPrice == null) return null;

  return (
    comissionPrice - getBookingServiceComissionInvoicedPrice(bookingService)
  );
};

// Returns the total amount corresponding to the client of a given accommodation service
export const getAccommodationServiceClientTotalPrice = (
  accommodationService,
  nights
) => {
  if (!accommodationService) return null;

  // If the payment frequency depends on consumption, the total price
  // is uncalculabe, return null
  if (
    accommodationService.paymentFrequency &&
    uncalculableFrequencies.includes(accommodationService.paymentFrequency)
  ) {
    return null;
  }

  let serviceTotalPrice = accommodationService.price;

  // If the payment frequency is daily, calculate the total price keeping
  // in mind the duration of the reservation
  if (accommodationService.paymentFrequency === "DAY" && nights) {
    serviceTotalPrice = accommodationService.price * nights;
  }

  return serviceTotalPrice;
};

// Returns the text version of the unit price corresponding to the client of a given accommodation service
export const getAccommodationServiceClientUnitPriceText = (
  accommodationService,
  locale
) => {
  if (!accommodationService) return null;
  if (!accommodationService.price && accommodationService.price !== 0)
    return null;
  if (!accommodationService.paymentFrequency) return null;

  const accommodationServiceUnitPrice = formatCurrency(
    accommodationService.price
  );
  const paymentFrequencyUnit =
    getPaymentFrequencyUnit(accommodationService.paymentFrequency, locale) ||
    "";

  return `${accommodationServiceUnitPrice}${paymentFrequencyUnit}`;
};

// Returns the text version of the unit price corresponding to the client of a given accommodation service
// or the unit price in case it can not be calculated
export const getAccommodationServiceClientTotalOrUnitPriceText = (
  accommodationService,
  nights,
  locale
) => {
  if (!accommodationService) return null;

  const accommodationServiceTotalPrice =
    getAccommodationServiceClientTotalPrice(accommodationService, nights);

  if (!accommodationServiceTotalPrice)
    return getAccommodationServiceClientUnitPriceText(
      accommodationService,
      locale
    );
  return formatCurrency(accommodationServiceTotalPrice);
};

// Revisat i OK
export const getBookingPaidPrice = (booking) => {
  if (!booking) {
    return null;
  }

  if (!booking.clientPayments?.length) {
    return 0;
  }

  return booking.clientPayments.reduce((acc, payment) => {
    if (
      payment.scope === "BOOKING" &&
      ["ACCOUNTED", "VERIFIED", "CONFIRMED", "PRE_CONFIRMED"].includes(
        payment.status
      )
    )
      return acc + payment.pvpAmount;
    return acc;
  }, 0);
};

// Revisat i OK
export const getBookingRefundedPrice = (booking) => {
  if (!booking) {
    return null;
  }

  if (!booking.clientRefunds?.length) {
    return 0;
  }

  return booking.clientRefunds.reduce((acc, payment) => {
    if (
      payment.scope === "BOOKING" &&
      ["ACCOUNTED", "VERIFIED", "CONFIRMED", "PRE_CONFIRMED"].includes(
        payment.status
      )
    )
      return acc + payment.pvpAmount;
    return acc;
  }, 0);
};

// Revisat i OK
export const getBookingPendingPrice = (booking) => {
  if (!booking) {
    return null;
  }

  const subtotal = booking.ratesPrice;
  const paid = getBookingPaidPrice(booking) || 0;
  const refunded = getBookingRefundedPrice(booking) || 0;

  return subtotal - paid + refunded;
};

// TODO: passar a i18n
// eslint-disable-next-line no-unused-vars
export const getCommentScopeName = (code, locale = "ca-ES") => {
  if (!code) return null;
  switch (code) {
    case "GENERAL":
      return null;
    case "ARRIVAL":
      return "Arribada";
    case "ACCOUNTING":
      return "Comptabilitat";
    case "SECURITY_DEPOSIT":
      return "Fiança";
    default:
      return code;
  }
};

// Revisat i OK
export const getTpvLink = ({
  locale = "en-GB",
  amount = null,
  email = null,
  comment = null,
  name = null,
  localizator = null,
  accommodation = null,
  checkin = null,
  checkout = null,
}) => {
  const link = `${TPV_BASE_LINK}/${simplifyLocale(locale)}`;
  const params = [];

  if (amount) {
    params.push(`amount=${amount}`);
  }
  if (email) {
    params.push(`email=${email.trim()}`);
  }
  if (comment) {
    params.push(`comment=${comment.trim().replaceAll(" ", "+")}`);
  }
  if (name) {
    params.push(`name=${name.trim().replaceAll(" ", "+")}`);
  }
  if (localizator) {
    params.push(`localizator=${localizator}`);
  }
  if (accommodation) {
    params.push(`accommodation=${accommodation.trim().replaceAll(" ", "+")}`);
  }
  if (checkin) {
    params.push(`checkin=${checkin}`);
  }
  if (checkout) {
    params.push(`checkout=${checkout}`);
  }

  return params.length ? `${link}?${params.join("&")}` : link;
};

// Revisat i OK
export const getAccommodationPoliceReportText = ({
  policeRegistrationName = null,
  policeRegistrationNumber = null,
  guestsAmount = null,
}) => {
  if (!policeRegistrationName || !policeRegistrationNumber || !guestsAmount) {
    return "";
  }
  const currentDate = new Date();
  const text = ["1"];
  text.push(`${policeRegistrationNumber || null}`);
  text.push(`${policeRegistrationName?.toUpperCase() || null}`);
  text.push(`${formatDateObjectToYmdDate(currentDate)}`);
  text.push(`${formatDateObjectToHiTime(currentDate)}`);
  text.push(`${guestsAmount}`);
  return text.join("|");
};

// Revisat i OK
export const getGuestPoliceReportText = ({ guest = null, checkin = null }) => {
  if (!checkin || !guest?.idNumber) {
    return "";
  }

  function getIdType(guest) {
    if (guest.nationality === "ES" && guest.idType === "DNI") {
      return "D";
    }

    switch (guest.idType) {
      case "PASSPORT":
        return "P";
      default:
        return "I";
    }
    // TODO: adaptar-ho per permisos de residència
    // Segons normativa Guàrdia Civil:
    // - D para el DNI
    // - P para el Pasaporte
    // - C para el Permiso de Conducir
    // - I para la carta o documento de identidad
    // - N para permiso de residencia español
    // - X para permiso de residencia de otro Estado Miembro de la Unión Europea
  }

  function getFirstLastName(guest) {
    if (!guest.lastName) {
      return null;
    }
    const splittedLastName = guest.lastName.split(" ");
    if (splittedLastName.length < 1) {
      return null;
    }
    const firstLastName = splittedLastName[0];
    const parsed = Diacritics.clean(firstLastName.toUpperCase()).replace(
      "-",
      " "
    );
    return parsed.length ? parsed : null;
  }

  function getSecondLastName(guest) {
    if (!guest.lastName) {
      return null;
    }
    const splittedLastName = guest.lastName.split(" ");
    if (splittedLastName.length <= 1) {
      return null;
    }
    const secondLastName = guest.lastName.substr(
      guest.lastName.indexOf(" ") + 1
    );
    const parsed = Diacritics.clean(secondLastName.toUpperCase()).replace(
      "-",
      " "
    );
    return parsed.length ? parsed : null;
  }

  function getFirstName(guest) {
    if (!guest.firstName) {
      return null;
    }
    const firstName = guest.firstName;
    const parsed = Diacritics.clean(firstName.toUpperCase()).replace("-", " ");
    return parsed.length ? parsed : null;
  }

  function getGender(guest) {
    switch (guest.gender) {
      case "MALE":
        return "M";
      default:
        return "F";
    }
  }

  const isSpanish = guest.nationality === "ES";
  const idNumber = guest.idNumber?.toUpperCase() || "";
  const idIssueDate = guest.idIssueDate ? new Date(guest.idIssueDate) : null;
  const birthDate = guest.birthDate ? new Date(guest.birthDate) : null;

  const text = [];
  text.push("2");
  text.push(isSpanish ? idNumber : "");
  text.push(isSpanish ? "" : idNumber);
  text.push(getIdType(guest) || "");
  text.push(formatDateObjectToYmdDate(idIssueDate) || "");
  text.push(getFirstLastName(guest) || "");
  text.push(getSecondLastName(guest) || "");
  text.push(getFirstName(guest) || "");
  text.push(getGender(guest) || "");
  text.push(formatDateObjectToYmdDate(birthDate) || "");
  text.push(guest.nationality?.toUpperCase() || "DE");
  text.push(checkin);
  return text.join("|");
};

// Revisat i OK
export const getHospedajesIdType = (idType) => {
  switch (idType) {
    case "PASSPORT":
      return "PAS";
    case "DNI":
      return "NIF";
    case "NIF":
      return "NIF";
    case "NIE":
      return "NIE";
    case "CIF":
      return "CIF";
    case "OTHER":
      return "OTRO";
    default:
      return "OTRO";
  }
};

// Revisat i OK
export const getHospedajesBookingParteViajerosXml = (data) => {
  const {
    hospedajesAccommodationCode,
    localizator,
    email: bookingEmail,
    date,
    checkin,
    checkout,
    reportableGuests,
  } = data || {};
  if (!hospedajesAccommodationCode || !reportableGuests?.length) return null;

  const generatePersonaXML = (guest) => {
    const {
      firstName,
      firstLastName,
      secondLastName,
      idType,
      idNumber,
      idSupportNumber,
      age,
      birthDate,
      address,
      addressCityName,
      addressCityCode,
      addressZip,
      addressCountry,
      phone,
      email: guestEmail,
      parentship,
    } = guest || {};
    const tipoDocumento = getHospedajesIdType(idType);
    const apellido2 = secondLastName || firstLastName;
    const fechaNacimiento = formatDateStringToDatabaseDate(birthDate);
    const alpha3Country = getAlpha3CountryCode(addressCountry);
    const email = guestEmail || bookingEmail;

    // TODO: ACABAR!!!!!

    // TODO: throw exception in some cases?
    // - firstName and firstLastName are required
    // - If tipoDocument = 'NIF' the secondLastName is required
    // - idNumber is required if guest is 18 or older
    // - birthDate is required
    // - address is required
    // - addressCountry is required and it is converted to alpha3 format
    // - addressZip is required
    // - addressCityCode is required if addressCountry is 'ESP'
    // - addressCityName is required if addressCountry is not 'ESP'

    return `
      <persona>
        <rol>VI</rol>
        <nombre>${firstName}</nombre>
        <apellido1>${firstLastName}</apellido1>
        ${
          tipoDocumento === "NIF" || !!apellido2
            ? `<apellido2>${apellido2}</apellido2>`
            : ""
        }
        <tipoDocumento>${tipoDocumento}</tipoDocumento>
        ${
          age >= 18 && idNumber
            ? `<numeroDocumento>${idNumber}</numeroDocumento>`
            : ""
        }
        ${
          ["NIF", "NIE"].includes(tipoDocumento) && idSupportNumber
            ? `<soporteDocumento>${idSupportNumber}</soporteDocumento>`
            : ""
        }
        <fechaNacimiento>${fechaNacimiento}</fechaNacimiento>
        <direccion>
          <direccion>${address}</direccion>
          ${
            alpha3Country === "ESP"
              ? `<codigoMunicipio>${addressCityCode}</codigoMunicipio>`
              : ""
          }
          <nombreMunicipio>${addressCityName}</nombreMunicipio>
          <codigoPostal>${addressZip}</codigoPostal>
          <pais>${alpha3Country}</pais>
        </direccion>
        ${phone ? `<telefono>${phone}</telefono>` : ""}
        ${email ? `<correo>${email}</correo>` : ""}
        ${parentship ? `<parentesco>${parentship}</parentesco>` : ""}
      </persona>`;
  };

  const personasXML = reportableGuests.map(generatePersonaXML).join("");
  const formattedDate = formatDateStringToDatabaseDate(date);
  const formattedCheckin = formatDateStringToDatabaseDate(checkin);
  const formattedCheckout = formatDateStringToDatabaseDate(checkout);

  const xml = `<?xml version="1.0" encoding="UTF-8"?>
  <ns2:peticion xmlns:ns2="http://www.neg.hospedajes.mir.es/altaParteHospedaje">
    <solicitud>
      <codigoEstablecimiento>${hospedajesAccommodationCode}</codigoEstablecimiento>
      <comunicacion>
        <contrato>
          <referencia>${localizator}</referencia>
          <fechaContrato>${formattedDate}</fechaContrato>
          <fechaEntrada>${formattedCheckin}T16:00:00</fechaEntrada>
          <fechaSalida>${formattedCheckout}T10:00:00</fechaSalida>
          <numPersonas>${reportableGuests.length}</numPersonas>
          <pago>
            <tipoPago>PLATF</tipoPago>
          </pago>
        </contrato>
        ${personasXML}
      </comunicacion>
    </solicitud>
  </ns2:peticion>`.replace(/\n\s*/g, "");

  return xml;
};

// Revisat i OK
export const getPlaceTypeName = (typeCode, locale = "en-GB") => {
  if (!typeCode) {
    return null;
  }

  switch (typeCode.toUpperCase()) {
    case "RESTAURANT":
      return i18n.t("Restaurant", locale);
    case "BAR":
      return i18n.t("Bar", locale);
    case "BEACH":
      return i18n.t("Platja", locale);
    case "CLUB":
      return i18n.t("Discoteca", locale);
    case "ATM":
      return i18n.t("Caixer automàtic", locale);
    case "MARKET":
      return i18n.t("Mercat", locale);
    case "MONUMENT":
      return i18n.t("Monument", locale);
    case "PARK":
      return i18n.t("Parc", locale);
    case "AIRPORT":
      return i18n.t("Aeroport", locale);
    case "PORT":
      return i18n.t("Port", locale);
    case "TAXI":
      return i18n.t("Aturada de taxi", locale);
    case "BUS":
      return i18n.t("Aturada d'autobús", locale);
    case "CITY":
      return i18n.t("Ciutat", locale);
    case "HOTEL":
      return i18n.t("Hotel", locale);
    case "OTHER":
      return i18n.t("Altre", locale);
    default:
      return typeCode.toUpperCase();
  }
};

// Revisat i OK
export const calculateBasePrice = (pvpPrice, vatPercentage) => {
  if (pvpPrice == null || vatPercentage == null) {
    return null;
  }

  return Math.round(pvpPrice / (1 + vatPercentage / 100));
};

// Revisat i OK
export const calculateBasePriceFromPvpPrice = (pvpPrice, vatPercentage) => {
  if (pvpPrice == null || vatPercentage == null) {
    return null;
  }

  return Math.round(pvpPrice / (1 + vatPercentage / 100));
};

// Revisat i OK
export const calculateVatPrice = (pvpPrice, vatPercentage) => {
  if (pvpPrice == null || vatPercentage == null) {
    return null;
  }

  return Math.round(
    (pvpPrice / (1 + vatPercentage / 100)) * (vatPercentage / 100)
  );
};

// Revisat i OK
export const calculatePercentagePriceFromBasePrice = (
  basePrice,
  percentage
) => {
  if (basePrice == null || percentage == null) {
    return null;
  }

  return Math.round(basePrice * (percentage / 100));
};

// Revisat i OK
export const calculatePvpPrice = (basePrice, vatPrice, irpfPrice) => {
  if (basePrice == null) {
    return null;
  }

  return basePrice + (vatPrice || 0) - (irpfPrice || 0);
};

// TODO
// export const calculateIrpfPrice = (pvpPrice, irpfPercentage) => {
//   if (pvpPrice == null || vatPercentage == null) {
//     return null;
//   }

//   return Math.round(
//     (pvpPrice / (1 + vatPercentage / 100)) * (vatPercentage / 100)
//   );
// };

// Revisat i OK
export const getKeysLocationName = (code, locale = "en-GB") => {
  if (!code) {
    return null;
  }

  switch (code.toUpperCase()) {
    case "LOCKBOX_BESIDE_MAIN_GATE":
      return i18n.t("Lockbox (barrera)", locale);
    case "LOCKBOX_BESIDE_HOUSE_DOOR":
      return i18n.t("Lockbox (porta casa)", locale);
    case "LOCKBOX_BESIDE_HOUSE_DOOR_OPEN_GATE":
      return i18n.t("Lockbox (porta casa, barrera oberta)", locale);
    case "UNDER_DOORMAT":
      return i18n.t("Davall l'estora", locale);
    case "INSIDE_PLANT_POT":
      return i18n.t("Dintre del cossiol", locale);
    case "OTHER":
      return i18n.t("Altre", locale);
    default:
      return code.toUpperCase();
  }
};

// TODO: passar a i18n
export const getBookingIncidenceStatusName = (code, locale = "ca-ES") => {
  if (!code) return null;
  switch (locale.toLowerCase()) {
    case "ca-es":
      switch (code.toUpperCase()) {
        case "OPEN":
          return "Oberta";
        case "PENDING":
          return "Pendent de 3rs";
        case "CLOSED":
          return "Tancada";
        default:
          return code.toUpperCase();
      }
    case "es-es":
      switch (code.toUpperCase()) {
        case "OPEN":
          return "Abierta";
        case "PENDING":
          return "Pend. terceros";
        case "CLOSED":
          return "Cerrada";
        default:
          return code.toUpperCase();
      }
    case "en-gb":
    default:
      switch (code.toUpperCase()) {
        case "OPEN":
          return "Open";
        case "PENDING":
          return "PTP";
        case "CLOSED":
          return "Closed";
        default:
          return code.toUpperCase();
      }
  }
};

// Revisat i OK
export const getBookingIncidenceStatusColor = (code) => {
  if (!code) {
    return null;
  }

  switch (code.toUpperCase()) {
    case "OPEN":
      return "danger";
    case "PENDING":
      return "warning";
    case "CLOSED":
      return "success";
    default:
      return "dark";
  }
};

// TODO: passar a i18n
export const getPromotionCategoryName = (code, locale = "ca-ES") => {
  if (!code) return null;

  const simplifiedLocale = simplifyLocale(locale);
  const uppercaseCode = code.toUpperCase();

  switch (simplifiedLocale) {
    case "ca":
      switch (uppercaseCode) {
        case "STANDARD":
          return "Estàndard";
        case "LAST_MINUTE":
          return "Last Minute";
        case "EARLY_BOOKING":
          return "Reserva anticipada";
        case "PROMO_CODE":
          return "Codi promocional";
        default:
          return uppercaseCode;
      }
    case "es":
      switch (uppercaseCode) {
        case "STANDARD":
          return "Estándar";
        case "LAST_MINUTE":
          return "Last Minute";
        case "EARLY_BOOKING":
          return "Reserva anticipada";
        case "PROMO_CODE":
          return "Código promocional";
        default:
          return uppercaseCode;
      }
    case "en":
    default:
      switch (uppercaseCode) {
        case "STANDARD":
          return "Standard";
        case "LAST_MINUTE":
          return "Last Minute";
        case "EARLY_BOOKING":
          return "Early booking";
        case "PROMO_CODE":
          return "Promo code";
        default:
          return uppercaseCode;
      }
  }
};

// Revisat i OK
export const getPromotionTypeName = (code, locale = "en-GB") => {
  if (!code) {
    return null;
  }

  const uppercaseCode = code.toUpperCase();

  switch (uppercaseCode) {
    case "PERCENTAGE_DISCOUNT":
      return i18n.t("Descompte (%)", locale);
    case "AMOUNT_DISCOUNT":
      return i18n.t("Descompte (€)", locale);
    case "FIXED_PRICE":
      return i18n.t("Preu fixe (€)", locale);
    case "FREE_NIGHTS":
      return i18n.t("Nits gratuïtes", locale);
    default:
      return uppercaseCode;
  }
};

// Revisat i OK
export const getPromotionScopeName = (code, locale = "en-GB") => {
  if (!code) {
    return null;
  }

  const uppercaseCode = code.toUpperCase();

  switch (uppercaseCode) {
    case "BOOKING":
      return i18n.t("Reserva", locale);
    case "WEEK":
      return i18n.t("Setmana", locale);
    case "NIGHT":
      return i18n.t("Nit", locale);
    case "BOOKING_GUEST":
      return i18n.t("Reserva/Hoste", locale);
    case "WEEK_GUEST":
      return i18n.t("Setmana/Hoste", locale);
    case "NIGHT_GUEST":
      return i18n.t("Nit/Hoste", locale);
    default:
      return uppercaseCode;
  }
};

// Revisat i OK
export const getPromotionValueText = (promotion, locale = "en-GB") => {
  if (!promotion) {
    return null;
  }

  const value = promotion.value || null;
  const type = promotion.type ? promotion.type.toUpperCase() : null;
  const scope = promotion.scope ? promotion.scope.toUpperCase() : null;

  if (!value || !type || (type !== "FREE_NIGHTS" && !scope)) {
    return null;
  }

  switch (type) {
    case "PERCENTAGE_DISCOUNT":
      return `${value} %`;
    case "AMOUNT_DISCOUNT":
    case "FIXED_PRICE":
      return formatCurrency(value);
    case "FREE_NIGHTS":
      return getNightsText(value, locale);
    default:
      return value;
  }
};

// Revisat i OK
export const getInitialServiceProps = (serviceCode) => {
  if (!serviceCode) {
    return null;
  }

  switch (serviceCode) {
    case "SECURITY_DEPOSIT":
      return {
        mandatory: true,
        chargable: true,
        paymentFrequency: "ONCE",
        paymentTime: "FINAL_PAYMENT",
        paymentMethods: ["CREDIT_CARD", "BANK_TRANSFER", "BIZUM"],
        pvp: 300,
        costPrice: null,
        commissionPercentage: null,
        amount: 1,
        invoiceHolder: null,
      };
    case "TOURIST_TAX":
      return {
        mandatory: true,
        chargable: true,
        paymentFrequency: "ONCE",
        paymentTime: "INITIAL_PAYMENT",
        paymentMethods: ["CREDIT_CARD", "BANK_TRANSFER", "BIZUM"],
        pvp: null,
        costPrice: null,
        commissionPercentage: null,
        amount: 1,
        invoiceHolder: null,
      };
    case "FINAL_CLEANING":
      return {
        mandatory: true,
        chargable: true,
        paymentFrequency: "ONCE",
        paymentTime: "INITIAL_PAYMENT",
        paymentMethods: ["CREDIT_CARD", "BANK_TRANSFER", "BIZUM"],
        pvp: null,
        costPrice: null,
        commissionPercentage: null,
        amount: 1,
        invoiceHolder: "AGENCY",
      };
    case "SUPERMARKET":
      return {
        mandatory: false,
        chargable: false,
        paymentFrequency: "ONCE",
        paymentTime: "PROVIDER",
        paymentMethods: ["CREDIT_CARD", "BANK_TRANSFER"],
        pvp: null,
        costPrice: null,
        commissionPercentage: 5,
        amount: null,
        invoiceHolder: "PROVIDER",
      };
    case "TRANSFER":
    case "AIRPORT_TRANSFER":
      return {
        mandatory: false,
        chargable: false,
        paymentFrequency: "ONCE",
        paymentTime: "PROVIDER",
        paymentMethods: ["CREDIT_CARD", "CASH"],
        pvp: null,
        costPrice: null,
        commissionPercentage: null,
        amount: null,
        invoiceHolder: "PROVIDER",
      };
    case "CAR_RENTAL":
      return {
        mandatory: false,
        chargable: false,
        paymentFrequency: "ONCE",
        paymentTime: "PROVIDER",
        paymentMethods: ["CREDIT_CARD"],
        pvp: null,
        costPrice: null,
        commissionPercentage: null,
        amount: null,
        invoiceHolder: "PROVIDER",
      };
    default:
      return null;
  }
};

// Revisat i OK
export const formatIbanNumber = (ibanNumber) => {
  // If the string is empty return early
  if (!ibanNumber?.length) {
    return null;
  }

  // Ensure the input is a string and trim any leading/trailing whitespace
  ibanNumber = String(ibanNumber).trim();

  // Remove any non-alphanumeric characters from the input value
  const cleanValue = ibanNumber.replace(/[^a-zA-Z0-9]/g, "");

  // Split the clean value into groups of 4 characters
  const groups = [];
  for (let i = 0; i < cleanValue.length; i += 4) {
    groups.push(cleanValue.slice(i, i + 4));
  }

  // Join the groups with spaces to create the formatted IBAN
  const formattedIBAN = groups.join(" ");

  // 29 digist: 24 digits of IBAN + 5 white spaces
  return formattedIBAN.toUpperCase().slice(0, 29);
};

export const isIbanNumberValid = (ibanNumber) => {
  // Remove spaces and convert to uppercase
  const iban = ibanNumber.replace(/\s/g, "").toUpperCase();

  // Check if the IBAN length is within common valid ranges
  if (iban.length < 15 || iban.length > 34) {
    return false;
  }

  // Check that the IBAN starts with a two-letter country code
  const country = iban.slice(0, 2);
  if (!/^[A-Z]{2}$/.test(country)) {
    return false;
  }

  // Move the first 4 characters to the end
  const rearrangedIBAN = iban.slice(4) + iban.slice(0, 4);

  // Convert letters to numbers (A=10, B=11, ..., Z=35)
  const digits = rearrangedIBAN
    .split("")
    .map((char) => {
      const code = char.charCodeAt(0);
      return code >= 65 && code <= 90 ? (code - 55).toString() : char;
    })
    .join("");

  // Perform modulo operation for long strings of digits
  let remainder = "";
  for (let i = 0; i < digits.length; i += 7) {
    const chunk = remainder + digits.substr(i, 7);
    remainder = chunk % 97;
  }

  return remainder === 1;
};

// Revisat i OK
export const formatLedgerAccount = (accountNumber) => {
  // If the string is empty return early
  if (!accountNumber?.length) {
    return null;
  }

  // Ensure the input is a string and trim any leading/trailing whitespace
  accountNumber = String(accountNumber).trim();

  const ACCOUNT_NUMBER_LENGTH = 10;

  const [startPart, endPart] = accountNumber.split(".");

  // If there's no after-dot part, return the account number making
  // sure that it is the correct length, adding extra 0's if needed.
  if (!endPart) {
    if (accountNumber.length < 10) {
      return accountNumber.padEnd(ACCOUNT_NUMBER_LENGTH, "0");
    }

    return accountNumber.slice(0, 10);
  }

  // If there are an after-dot part, add middle padding with as many 0's
  // as need to reach the correct length.
  const missingLength =
    ACCOUNT_NUMBER_LENGTH - startPart.length - endPart.length;
  const middlePadding = "".padStart(missingLength, "0");

  return `${startPart}${middlePadding}${endPart}`.slice(0, 10);
};

// Revisat i OK
export const getContractProviderTypeName = (code, locale = "en-GB") => {
  if (!code) {
    return null;
  }

  switch (code.toUpperCase()) {
    case "CLEANING":
      return i18n.t("Neteja", locale);
    case "HOUSEHOLD_CLOTHES":
      return i18n.t("Roba de la llar", locale);
    case "HOUSEHOLD_CLOTHES_CLEANING":
      return i18n.t("Bugaderia", locale);
    case "INCIDENCES":
      return i18n.t("Gestió d'incidències", locale);
    case "SWIMMING_POOL":
      return i18n.t("Manteniment de piscina", locale);
    case "GARDEN":
      return i18n.t("Manteniment de jardí", locale);
    default:
      return code.toUpperCase();
  }
};

export const sameSign = (num1, num2) => {
  if (typeof num1 !== "number" || typeof num2 !== "number") {
    return false;
  }
  return Math.sign(num1) === Math.sign(num2);
};

export const sameObjects = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];

    const areValuesObjects = val1 instanceof Object && val2 instanceof Object;

    if (
      (areValuesObjects && !sameObjects(val1, val2)) ||
      (!areValuesObjects && val1 !== val2)
    ) {
      return false;
    }
  }

  return true;
};

export const getRealEstateOperationName = (opeartionCode, locale = "ca-ES") => {
  if (!opeartionCode) {
    return null;
  }

  const code = opeartionCode.toUpperCase();

  switch (simplifyLocale(locale)) {
    case "ca":
      switch (code) {
        case "BUY":
          return "Compra";
        case "SELL":
          return "Venda";
        case "RENT_OFFER":
          return "Oferta de lloguer";
        case "RENT_REQUEST":
          return "Lloguer";
      }
      break;
    case "es":
      switch (code) {
        case "BUY":
          return "Compra";
        case "SELL":
          return "Venta";
        case "RENT_OFFER":
          return "Oferta de alquiler";
        case "RENT_REQUEST":
          return "Alquiler";
      }
      break;
    case "en":
      switch (code) {
        case "BUY":
          return "Buy";
        case "SELL":
          return "Sell";
        case "RENT_OFFER":
          return "Rental Offer";
        case "RENT_REQUEST":
          return "Rent";
      }
      break;
    case "de":
      switch (code) {
        case "BUY":
          return "Kaufen";
        case "SELL":
          return "Verkauf";
        case "RENT_OFFER":
          return "Mietangebot";
        case "RENT_REQUEST":
          return "Mieten";
      }
      break;
    default:
      switch (code) {
        case "BUY":
          return "Buy";
        case "SELL":
          return "Sell";
        case "RENT_OFFER":
          return "Rental Offer";
        case "RENT_REQUEST":
          return "Rent";
      }
      break;
  }
};

export const generateBookingsListTaxReport = (reportData) => {
  debugger;
  if (!Object.keys(reportData).length) {
    return null;
  }

  const bookings = reportData.bookings || null;
  const startDate = reportData.startDate || null;
  const endDate = reportData.endDate || null;
  const owner = reportData.owner || null;
  const coOwners = reportData.coOwners || null;

  if (!bookings.length || !startDate || !endDate) {
    return null;
  }

  const comissionBookings = bookings.filter(
    (booking) => booking.contract?.billingType === "COMISSION"
  );

  const suburanceBookings = bookings.filter(
    (booking) => booking.contract?.billingType === "SUBURANCE"
  );

  const filterBookingsByDates = (booking) => {
    if (!["CONFIRMED", "COMPLETED"].includes(booking.status)) {
      return false;
    }

    const checkin = formatDateStringToDatabaseDate(booking.checkin);

    return checkin >= startDate && checkin <= endDate;
  };

  const sortBookingsByCheckinDate = (a, b) => {
    if (a.checkin > b.checkin) return 1;
    if (b.checkin > a.checkin) return -1;
    return 0;
  };

  const filteredComissionBookings = comissionBookings.length
    ? comissionBookings
        .filter(filterBookingsByDates)
        .sort(sortBookingsByCheckinDate)
    : [];

  const filteredSuburanceBookings = suburanceBookings.length
    ? suburanceBookings
        .filter(filterBookingsByDates)
        .sort(sortBookingsByCheckinDate)
    : [];

  if (!filteredComissionBookings.length && !filteredSuburanceBookings.length) {
    return null;
  }

  const text = [];

  // COMISSION BOOKINGS
  if (filteredComissionBookings.length) {
    if (filteredSuburanceBookings.length) {
      text.push("RESERVES A COMISSIÓ");
      text.push("");
    }
    text.push(
      "Reserva,Allotjament,Nom,País,Codi fiscal,Adults,Nins/es,Nadons,Entrada,Sortida,Vespres,Total,Propietari,Foravila"
    );

    filteredComissionBookings.forEach((booking) => {
      const bookingText = [];

      const clientPrice = booking.pvpPrice ? booking.pvpPrice / 100 : 0;
      const ownerPrice = booking.ownerPrice ? booking.ownerPrice / 100 : 0;
      const agencyPrice = Math.round((clientPrice - ownerPrice) * 100) / 100;

      bookingText.push(booking.localizator || "");
      bookingText.push(booking.accommodation?.name || "");
      bookingText.push(booking.client?.fullName || "");
      bookingText.push(booking.client?.location?.country || "");
      bookingText.push(
        booking.client?.idNumber ? `${booking.client.idNumber}` : ""
      );
      bookingText.push(booking.adults || 0);
      bookingText.push(booking.children || 0);
      bookingText.push(booking.babies || 0);
      bookingText.push(formatDateStringToDate(booking.checkin) || "");
      bookingText.push(formatDateStringToDate(booking.checkout) || "");
      bookingText.push(booking.nights || "");
      bookingText.push(clientPrice);
      bookingText.push(ownerPrice);
      bookingText.push(agencyPrice);

      text.push(bookingText.join(","));
    });

    const totalDays = filteredComissionBookings.reduce((acc, booking) => {
      return (acc += booking.nights);
    }, 0);

    const clientTotalPrice = filteredComissionBookings.reduce(
      (acc, booking) => {
        const clientPrice = booking.pvpPrice ? booking.pvpPrice / 100 : 0;
        const currentTotal = Math.round((acc += clientPrice) * 100) / 100;
        return currentTotal;
      },
      0
    );

    const ownerTotalPrice = filteredComissionBookings.reduce((acc, booking) => {
      const ownerPrice = booking.ownerPrice ? booking.ownerPrice / 100 : 0;
      const currentTotal = Math.round((acc += ownerPrice) * 100) / 100;
      return currentTotal;
    }, 0);

    const agencyTotalPrice =
      Math.round((clientTotalPrice - ownerTotalPrice) * 100) / 100;

    text.push(
      `,,,,,,,,,,${totalDays},${clientTotalPrice},${ownerTotalPrice},${agencyTotalPrice}`
    );
  }

  // SUBURANCE BOOKINGS
  if (filteredSuburanceBookings.length) {
    if (filteredComissionBookings.length) {
      text.push("");
      text.push("RESERVES A SUBARRENDAMENT");
      text.push("");
    }
    text.push(
      "Reserva,Allotjament,Nom,País,Codi fiscal,Adults,Nins/es,Nadons,Entrada,Sortida,Vespres,Total,Total sense IVA,Propietari,% Propietari"
    );

    filteredSuburanceBookings.forEach((booking) => {
      const bookingText = [];

      const clientPrice = booking.pvpPrice ? booking.pvpPrice / 100 : 0;
      const noVatClientPrice = Math.round((clientPrice / 1.1) * 100) / 100;
      const ownerPrice = booking.ownerPrice ? booking.ownerPrice / 100 : 0;
      const ownerPercentage =
        Math.round((100 / noVatClientPrice) * ownerPrice * 100) / 100;

      bookingText.push(booking.localizator || "");
      bookingText.push(booking.accommodation?.name || "");
      bookingText.push(booking.client?.fullName || "");
      bookingText.push(booking.client?.location?.country || "");
      bookingText.push(
        booking.client?.idNumber ? `${booking.client.idNumber}` : ""
      );
      bookingText.push(booking.adults || 0);
      bookingText.push(booking.children || 0);
      bookingText.push(booking.babies || 0);
      bookingText.push(formatDateStringToDate(booking.checkin) || "");
      bookingText.push(formatDateStringToDate(booking.checkout) || "");
      bookingText.push(booking.nights || "");
      bookingText.push(clientPrice); // Total
      bookingText.push(noVatClientPrice); // Total sense IVA
      bookingText.push(ownerPrice);
      bookingText.push(ownerPercentage);

      text.push(bookingText.join(","));
    });

    const totalDays = filteredSuburanceBookings.reduce((acc, booking) => {
      return (acc += booking.nights);
    }, 0);

    const clientTotalPrice = filteredSuburanceBookings.reduce(
      (acc, booking) => {
        const clientPrice = booking.pvpPrice ? booking.pvpPrice / 100 : 0;
        const currentTotal = Math.round((acc += clientPrice) * 100) / 100;
        return currentTotal;
      },
      0
    );

    const noVatClientTotalPrice = filteredSuburanceBookings.reduce(
      (acc, booking) => {
        const clientPrice = booking.pvpPrice ? booking.pvpPrice / 100 : 0;
        const noVatClientPrice = Math.round((clientPrice / 1.1) * 100) / 100;
        const currentTotal = Math.round((acc += noVatClientPrice) * 100) / 100;
        return currentTotal;
      },
      0
    );

    const ownerTotalPrice = filteredSuburanceBookings.reduce((acc, booking) => {
      const ownerPrice = booking.ownerPrice ? booking.ownerPrice / 100 : 0;
      const currentTotal = Math.round((acc += ownerPrice) * 100) / 100;
      return currentTotal;
    }, 0);

    const ownerTotalPercentage =
      Math.round((100 / noVatClientTotalPrice) * ownerTotalPrice * 100) / 100;

    text.push(
      `,,,,,,,,,,${totalDays},${clientTotalPrice},${noVatClientTotalPrice},${ownerTotalPrice},${ownerTotalPercentage}`
    );
  }

  // DATES
  const today = new Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  }).format(new Date());
  const period = `${formatDateStringToDate(
    startDate
  )} a ${formatDateStringToDate(endDate)}`;
  text.push("");
  text.push(`Data d'exportació: ${today}`);
  text.push(`Període d'exportació: ${period}`);

  // AGENCY INFO
  text.push("");
  text.push("FORA-VILA SA POBLA S.L.");
  text.push("B57825994");

  // OWNERS INFO
  if (owner) {
    text.push("");
    if (coOwners.length) {
      const coownersText = [];
      coownersText.push(`${owner.fullName} (${owner.idNumber})`);
      coOwners.forEach((coOwner) => {
        coownersText.push(`${coOwner.fullName} (${coOwner.idNumber})`);
      });
      text.push(`Propietaris: ${coownersText.join(" - ")}`);
    } else {
      text.push(`Propietari: ${owner.fullName} (${owner.idNumber})`);
    }
  }

  return text.join("\n");
};
